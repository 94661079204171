/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { Order, ShipmentQuantities } from "../../../../models/order";
import { useSort } from "../../../../hooks/useSort";
import { Column } from "../../../../tables/models";
import { ActionButton } from "../../../../components/buttons";
import { createShippingDetailsRequest } from "../api/shippingDetailsApi";
import { useNotification } from "../../../../utils/notification/notificationContext";

function RequestShippingDetails({
    setOpenRequestActionsModal,
    selectedOrders,
    setSelectedOrders,
}: {
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrders: Order[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
}) {
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [notes, setNotes] = useState("");

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );
    const hiddenHeaders: string[] = [
        "select",
        "location",
        "quick_actions",
        "input_shelf_location",
    ];

    const [loading, setLoading] = useState(false);
    const handleCreate = async () => {
        setLoading(true);
        const order_ids = selectedOrders.map((o) => o.order_id);
        const data = await createShippingDetailsRequest(
            order_ids,
            shipmentQuantities,
            notes
        );
        if (data.status === "success") {
            setOpenRequestActionsModal(false);
            setSelectedOrders([]);
            setNotes("");
            setShipmentQuantities({});
            showNotification(
                "Created shipping details request.",
                "",
                "success"
            );
        } else {
            showNotification(
                "Failed to create shipping details request",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                <OrdersTable
                    orderStatus="inbound"
                    orders={selectedOrders}
                    results={null}
                    selectedOrders={[]}
                    setSelectedOrders={setSelectedOrders}
                    sortLoading={sortLoading}
                    sortConfig={sortConfig}
                    toggleSort={toggleSort}
                    handleRefetch={() => {}}
                    client_type="admin"
                    setTotalSum={setTotalSum}
                    columnOrder={unitsColumnOrder}
                    hiddenHeaders={hiddenHeaders}
                    shipmentQuantities={shipmentQuantities}
                    handleQuantityChange={handleQuantityChange}
                    shipmentInputName={"Quantity Requested"}
                />

                <div className="mx-1">
                    <label
                        htmlFor="comment"
                        className="block text-sm/6 font-medium text-gray-900 mx-1"
                    >
                        Notes
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="comment"
                            name="comment"
                            rows={2}
                            className="block w-full rounded-md px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-accent sm:text-sm/6"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="flex flex-row-reverse">
                <ActionButton
                    label="Request details"
                    handleClick={() => handleCreate()}
                    height={9}
                    loading={loading}
                />
            </div>
        </>
    );
}

export default RequestShippingDetails;
