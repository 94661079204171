/** @format */
import { v4 as uuidv4 } from "uuid";
import {
    ChangeEvent,
    KeyboardEvent,
    useCallback,
    useEffect,
    useState,
} from "react";
import { InputField, InputNumberField } from "../../../components/inputField";
import Client from "../../../models/clients";
import { searchData } from "../api/clientsApi";
import {
    PlusCircleIcon,
    TrashIcon,
    XCircleIcon,
} from "@heroicons/react/20/solid";
import dayjs, { Dayjs } from "dayjs";
import { ActionButton, SecondaryButton } from "../../../components/buttons";
import LoadingWheel from "../../../components/loadingWheel";
import { TrackingInfo } from "../../../models/trackings";
import { CreateOrder, ReceivedOrder } from "../../../models/order";
import { createReceivedOrder, matchTrackingToOrder } from "../api/receivedApi";
import BoxesPopup from "../outbound/components/boxesPopup";
import { Box } from "../../../models/adminDashboard";
import { fetchBoxes } from "../api/settingsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import ServicesPopup from "../outbound/components/servicesPopup";
import { ShipmentService } from "../../../models/shipments";
import { formatDateFromObject } from "../../../utils/date/date_formatter";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { cleanTrackingNumber } from "../../../utils/carriers/cleanTrackingNumber";
import { CiBarcode } from "react-icons/ci";
import UpcModal from "./upcModal";
import { apiRequest } from "../../../utils/api/apiRequest";
import { debounce } from "lodash";
import { ScannedBarcode } from "../../../models/scannedBarcode";
import { BsUpcScan } from "react-icons/bs";
import CopyButton from "../../../components/copyButton";

export function HoverExpandedText({
    show,
    text,
}: {
    show: boolean;
    text: string;
}) {
    return (
        <>
            {show ? (
                <div className="absolute top-0 left-0 w-full h-full">
                    <div className="hidden group-hover:block absolute z-10 bg-gray-200 text-black text-sm rounded-md p-2 shadow-lg">
                        {text}
                    </div>
                </div>
            ) : null}
        </>
    );
}

export function SuggestedNamesDropDown({
    show,
    itemsList,
    index,
    updateOrder,
    setIsFocused,
    columnName,
}: {
    show: boolean;
    itemsList: string[];
    index: number;
    updateOrder: (
        index: number,
        field: keyof ReceivedOrder | keyof CreateOrder,
        value: any
    ) => void;
    setIsFocused: React.Dispatch<
        React.SetStateAction<{
            column: string;
            index: number;
        } | null>
    >;
    columnName: keyof ReceivedOrder | keyof CreateOrder;
}) {
    return (
        <>
            {show ? (
                <div
                    tabIndex={-1}
                    className="suggestions-box absolute border border-gray-100 max-h-96 overflow-auto top-16 max-w-96 flex flex-col divide-y w-full rounded-md shadow-lg z-50 bg-white"
                >
                    {itemsList.map((itemName, subindex) => (
                        <div
                            key={subindex}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                                updateOrder(index, columnName, itemName);
                                setIsFocused(null);
                            }}
                            className="flex flex-col hover:bg-gray-100 cursor-pointer py-2 px-2"
                        >
                            <span className="text-sm font-medium">
                                {itemName}
                            </span>
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

function CheckInOrder() {
    const { showNotification } = useNotification();
    const [clients, setClients] = useState<Client[]>([]);
    const [clientNotFoundName, setClientNotFoundName] = useState("");
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [clientSearch, setClientSearch] = useState("");
    const [clientsLoading, setClientsLoading] = useState(false);

    const [matchLoading, setMatchLoading] = useState(false);
    const [matchedOrder, setMatchedOrder] = useState<ReceivedOrder | null>(
        null
    );

    const [trackings, setTrackings] = useState<TrackingInfo[]>([]);
    const [selectedTracking, setSelectedTracking] =
        useState<TrackingInfo | null>(null);
    const [trackingNumber, setTrackingNumber] = useState("");
    const [trackingSearch, setTrackingSearch] = useState("");
    const [trackingsLoading, setTrackingsLoading] = useState(false);

    const [isReshippingOrder, setIsReshippingOrder] = useState(true);

    const [isMatchOrder, setIsMatchOrder] = useState(false);

    useEffect(() => {
        searchData(
            clientSearch,
            "",
            "clients",
            "sub_page",
            setClients,
            setClientsLoading,
            () => {}
        );
    }, [clientSearch]);

    useEffect(() => {
        searchData(
            trackingSearch,
            selectedClient?.user_id,
            "inbound",
            "trackings",
            setTrackings,
            setTrackingsLoading,
            () => {}
        );
    }, [trackingSearch]);

    useEffect(() => {
        const fetchData = async () => {
            setMatchLoading(true);
            const data = await matchTrackingToOrder(
                selectedClient?.user_id || "",
                trackingNumber
            );
            if (data.status === "success") {
                setIsMatchOrder(true);
                setOrders(data.data);
                setExpandedIndices(
                    data.data &&
                        data.data.map((_: string, index: number) => index)
                );
                if (data.data.length === 1) {
                    setMatchedOrder(data.data[0]);
                }
            } else {
                addOrder();
            }
            setMatchLoading(false);
        };
        if (
            trackingNumber.length > 0 &&
            (selectedClient || clientNotFoundName !== "")
        ) {
            fetchData();
        }
    }, [trackingNumber]);

    const removeSelectedClient = () => {
        setTrackings([]);
        setOrders([]);
        setSelectedTracking(null);
        setTrackingNumber("");
        setTrackingSearch("");
        setMatchedOrder(null);
        setIsReshippingOrder(true);
        setClientNotFoundName("");
        setCarrier("");
        setIsMatchOrder(false);
        setSelectedClient(null);
        setSelectedBoxes([]);
        setSelectedServices([]);

        setScannedBarcodes([]);
        setCurrentInput("");

        setAdditionalTrackings([]);
        setAdditionalTrackingsCurrentInput("");
    };

    const removeSelectedTracking = () => {
        setTrackings([]);
        setOrders([]);
        setSelectedTracking(null);
        setTrackingNumber("");
        setTrackingSearch("");
        setCarrier("");
        setIsReshippingOrder(true);
        setIsMatchOrder(false);
        setMatchedOrder(null);
        setSelectedBoxes([]);
        setSelectedServices([]);

        setScannedBarcodes([]);
        setCurrentInput("");

        setAdditionalTrackings([]);
        setAdditionalTrackingsCurrentInput("");
    };

    const clearFields = () => {
        setOrders([]);
    };

    const [carrier, setCarrier] = useState("");

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSelectTracking();
        }
    };

    const handleSelectTracking = (tracking?: string) => {
        if (tracking) {
            const [cleanedNumber, carrier] = cleanTrackingNumber(tracking);
            setTrackingNumber(cleanedNumber);
            setCarrier(carrier);
        } else {
            const [cleanedNumber, carrier] =
                cleanTrackingNumber(trackingSearch);
            setTrackingNumber(cleanedNumber);
            setCarrier(carrier);
        }
    };

    const [boxes, setBoxes] = useState<Box[]>([]);
    const [selectedBoxes, setSelectedBoxes] = useState<Box[]>([]);

    const [selectedServices, setSelectedServices] = useState<ShipmentService[]>(
        []
    );

    useEffect(() => {
        const fetchData = async () => {
            // setBoxesLoading(true);
            const data = await fetchBoxes();
            if (data.status === "success") {
                setBoxes(data.data.boxes);
            } else {
                showNotification(
                    "Failed to fetch boxes",
                    data.message,
                    "error"
                );
            }
            // setBoxesLoading(false);
        };
        fetchData();
    }, []);

    const [createLoading, setCreateLoading] = useState(false);
    const handleCreate = async () => {
        setCreateLoading(true);
        const data = await createReceivedOrder(
            selectedClient ? selectedClient.user_id : null,
            clientNotFoundName !== "" ? clientNotFoundName : null,
            matchedOrder
                ? matchedOrder.order_id
                    ? matchedOrder.order_id
                    : null
                : null,
            trackingNumber,
            carrier,
            orders,
            isReshippingOrder &&
                matchedOrder?.shipping_labels &&
                matchedOrder.shipping_labels.length > 0
                ? true
                : false,
            selectedBoxes,
            selectedServices,
            matchedOrder
                ? orders.find(
                      (order) => order.order_id === matchedOrder.order_id
                  )?.location || null
                : null,
            scannedBarcodes,
            additionalTrackings
        );
        if (data.status === "success") {
            if (matchedOrder && orders.length > 1) {
                showNotification(
                    "Checked in package.",
                    `There are ${
                        orders.length - 1
                    } orders with this tracking left.`,
                    "success"
                );
                setMatchedOrder(null);
                setOrders((prevOrders) =>
                    prevOrders.filter(
                        (order) => order.order_id !== matchedOrder.order_id
                    )
                );
                setSelectedBoxes([]);
                setSelectedServices([]);
                setScannedBarcodes([]);
                setCurrentInput("");
                setAdditionalTrackingsCurrentInput("");
                setAdditionalTrackings([]);
                setScanAdditionalTrackings(false);
            } else {
                showNotification("Checked in package.", "", "success");
                removeSelectedClient();
                clearFields();
            }
        } else {
            showNotification(
                "Failed to create received order",
                data.message,
                "error"
            );
        }
        setCreateLoading(false);
    };

    const [orders, setOrders] = useState<ReceivedOrder[]>([]);
    const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

    const addOrder = () => {
        setOrders([
            ...orders,
            {
                order_id: null,
                received_order_id: uuidv4(),
                user_id: "",
                date: formatDateFromObject(dayjs()),
                retailer: "",
                item_name: "",
                quantity: "",
                size: "",
                color: "",
                brand_sku: "",
                check_in_notes: "",
                team_member_notes: "",
                client_notes: "",
                tracking_number: "",
                carrier: "",
                location: "",
                upc: "",
                status: "received",
            },
        ]);
        setExpandedIndices([orders.length]);
    };

    const removeMatchedOrders = () => {
        setOrders([
            {
                order_id: null,
                received_order_id: uuidv4(),
                user_id: "",
                date: formatDateFromObject(dayjs()),
                retailer: "",
                item_name: "",
                quantity: "",
                size: "",
                color: "",
                brand_sku: "",
                check_in_notes: "",
                team_member_notes: "",
                client_notes: "",
                tracking_number: "",
                carrier: "",
                location: "",
                upc: "",
                status: "received",
            },
        ]);
        setExpandedIndices([0]);
    };

    const removeOrder = (index: number) => {
        setOrders((prevOrders) => prevOrders.filter((_, i) => i !== index));
    };

    const updateOrder = (
        index: number,
        field: keyof ReceivedOrder | keyof CreateOrder,
        value: any
    ) => {
        setOrders((prevOrders) =>
            prevOrders.map((order, i) =>
                i === index ? { ...order, [field]: value } : order
            )
        );
        debouncedSearch(value, field);
    };

    const toggleExpand = (index: number) => {
        setExpandedIndices(
            (prev) =>
                prev.includes(index)
                    ? prev.filter((i) => i !== index) // Remove from expanded
                    : [...prev, index] // Add to expanded
        );
    };

    const [selectedOrderForUpc, setSelectedOrderForUpc] =
        useState<ReceivedOrder | null>(null);
    const [openUpcModal, setOpenUpcModal] = useState(false);

    useEffect(() => {
        if (selectedOrderForUpc) setOpenUpcModal(true);
    }, [selectedOrderForUpc]);

    useEffect(() => {
        if (!openUpcModal) setSelectedOrderForUpc(null);
    }, [openUpcModal]);

    const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
        {}
    );
    const setColumnLoading = (column_name: string, isLoading: boolean) => {
        setLoadingStates((prev) => ({
            ...prev,
            [column_name]: isLoading,
        }));
    };
    const isColumnLoading = (column_name: string) =>
        loadingStates[column_name] || false;

    const [focusedField, setFocusedField] = useState<null | {
        column: string;
        index: number;
    }>(null);
    const [columnSuggestions, setColumnSuggestions] = useState<
        Record<string, string[]>
    >({});

    const [itemsLoading, setItemsLoading] = useState(false);
    const searchItemNames = async (name: string, column_name: string) => {
        if (!name || name === "") {
            setColumnSuggestions((prev) => ({
                ...prev,
                [column_name]: [], // Clear suggestions for the column
            }));
            setColumnLoading(column_name, false);
            return;
        }
        setColumnLoading(column_name, true);

        const data = await apiRequest(
            `/admin/received/items-list?name=${name}&column_name=${column_name}`
        );
        if (data.status === "success") {
            setColumnSuggestions((prev) => ({
                ...prev,
                [column_name]: data.data || [], // Update suggestions for the column
            }));
        }
        setColumnLoading(column_name, false);
    };

    const debouncedSearch = useCallback(
        debounce(
            (value: string, column_name: string) =>
                searchItemNames(value, column_name),
            200
        ),
        []
    );

    const getSuggestionsForColumn = (column_name: string) =>
        columnSuggestions[column_name] || [];

    const [scannedBarcodes, setScannedBarcodes] = useState<ScannedBarcode[]>(
        []
    );

    const [currentInput, setCurrentInput] = useState<string>("");

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentInput(e.target.value);
    };

    const handleTrackingKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();

            const [trackingNumber, carrier] = cleanTrackingNumber(
                currentInput.trim()
            );

            const newScannedBarcode: ScannedBarcode = {
                id: uuidv4(), // Generate a unique ID
                barcode: trackingNumber,
                dateScanned: new Date().toLocaleString(),
                isLoading: false, // Set loading to true initially
                carrier: carrier,
            };
            setScannedBarcodes((prev) => [...prev, newScannedBarcode]);

            setCurrentInput("");
        }
    };

    const removeBarcode = (id: string) => {
        setScannedBarcodes((prev) => prev.filter((item) => item.id !== id));
    };

    const [scanAdditionalTrackings, setScanAdditionalTrackings] =
        useState(false);
    const [additionalTrackings, setAdditionalTrackings] = useState<
        ScannedBarcode[]
    >([]);

    const [
        additionalTrackingsCurrentInput,
        setAdditionalTrackingsCurrentInput,
    ] = useState<string>("");

    const additionalTrackingsHandleInputChange = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        setAdditionalTrackingsCurrentInput(e.target.value);
    };

    const additionalTrackingsHandleTrackingKeyDown = (
        e: KeyboardEvent<HTMLInputElement>
    ) => {
        if (e.key === "Enter" && additionalTrackingsCurrentInput.trim()) {
            e.preventDefault();

            const [trackingNumber, carrier] = cleanTrackingNumber(
                additionalTrackingsCurrentInput.trim()
            );

            const newScannedBarcode: ScannedBarcode = {
                id: uuidv4(), // Generate a unique ID
                barcode: trackingNumber,
                dateScanned: new Date().toLocaleString(),
                isLoading: false, // Set loading to true initially
                carrier: carrier,
            };
            setAdditionalTrackings((prev) => [...prev, newScannedBarcode]);

            setAdditionalTrackingsCurrentInput("");
        }
    };

    const additionalTrackingsRemoveBarcode = (id: string) => {
        setAdditionalTrackings((prev) => prev.filter((item) => item.id !== id));
    };

    return (
        <div className="pt-4">
            <h2 className="text-lg font-semibold">Client & Tracking</h2>

            <div>
                <div className="flex flex-row items-center pt-4 space-x-4">
                    {!selectedClient && clientNotFoundName === "" ? (
                        <div className="relative z-10">
                            <div className="flex flex-row items-center space-x-2">
                                <div className="w-64">
                                    <InputField
                                        label="Client Name"
                                        value={clientSearch}
                                        onChange={(e) =>
                                            setClientSearch(e.target.value)
                                        }
                                        loading={clientsLoading}
                                    />
                                </div>
                            </div>
                            <div className="absolute max-h-96 overflow-auto top-16 max-w-64 flex flex-col divide-y w-full rounded-md shadow-lg z-50 bg-white">
                                {clients.map((client) => (
                                    <div
                                        key={client.user_id}
                                        onClick={() =>
                                            setSelectedClient(client)
                                        }
                                        className="flex flex-col hover:bg-gray-100 cursor-pointer py-2 px-2"
                                    >
                                        <span className="text-sm font-medium">
                                            {client.first_name +
                                                " " +
                                                client.last_name}
                                        </span>
                                        <span className="text-xs text-gray-700">
                                            {client.email}
                                        </span>
                                    </div>
                                ))}
                                {clientSearch.length > 0 && (
                                    <div
                                        onClick={() =>
                                            setClientNotFoundName(clientSearch)
                                        }
                                        className="flex flex-col hover:bg-gray-100 cursor-pointer py-2 px-2"
                                    >
                                        <span className="text-sm font-medium">
                                            Other
                                        </span>
                                        <span className="text-xs text-gray-700">
                                            Name on package not listed
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="w-64 flex flex-row items-center space-x-2">
                            <InputField
                                label="Client Name"
                                value={
                                    selectedClient
                                        ? selectedClient.first_name +
                                          " " +
                                          selectedClient.last_name
                                        : clientNotFoundName
                                }
                                onChange={() => {}}
                                disabled={true}
                            />
                            <XCircleIcon
                                className="w-7 h-7 text-red-600 mt-5 hover:text-red-500 cursor-pointer"
                                onClick={() => removeSelectedClient()}
                            />
                        </div>
                    )}
                    {(selectedClient || clientNotFoundName !== "") &&
                    !selectedTracking &&
                    trackingNumber.length === 0 ? (
                        <div className="relative ">
                            <div className="flex flex-row items-center space-x-2">
                                <div className="text-left w-full space-y-0.5">
                                    <div className="flex flex-row items-center justify-between">
                                        <span className="font-medium text-sm">
                                            Tracking Number
                                        </span>
                                    </div>
                                    <div className="relative w-64">
                                        <input
                                            value={trackingSearch}
                                            onChange={(e) =>
                                                setTrackingSearch(
                                                    e.target.value
                                                )
                                            }
                                            className={`disabled:bg-gray-100 disabled:text-gray-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6`}
                                            onKeyDown={handleKeyDown}
                                        />
                                        {trackingsLoading && (
                                            <div className="absolute top-1.5 right-2">
                                                <LoadingWheel />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pt-5">
                                    <ActionButton
                                        label="Confirm"
                                        handleClick={() =>
                                            handleSelectTracking()
                                        }
                                        height={9}
                                    />
                                </div>
                            </div>
                            <div className="absolute max-h-96 overflow-auto top-16 max-w-64 flex flex-col divide-y w-full rounded-md shadow-lg z-50 bg-white">
                                {trackings.map((tracking) => (
                                    <div
                                        key={tracking.tracking_id}
                                        onClick={() =>
                                            handleSelectTracking(
                                                tracking.tracking
                                            )
                                        }
                                        className="flex flex-col hover:bg-gray-100 cursor-pointer py-2 px-2"
                                    >
                                        <span className="text-sm font-medium">
                                            {tracking.tracking}
                                        </span>
                                        <span className="text-xs text-gray-700">
                                            {tracking.carrier}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (selectedClient || clientNotFoundName !== "") &&
                      (selectedTracking || trackingNumber.length > 0) ? (
                        <div className="flex flex-row items-center justify-between w-full">
                            <div className="flex flex-row items-center space-x-2 w-full">
                                <div className="w-64">
                                    <InputField
                                        label="Tracking Number"
                                        value={
                                            selectedTracking
                                                ? selectedTracking.tracking
                                                : trackingNumber
                                        }
                                        onChange={() => {}}
                                        disabled={true}
                                    />
                                </div>
                                <div className="w-fit relative">
                                    <InputField
                                        label="Carrier"
                                        value={carrier}
                                        onChange={() => {}}
                                        disabled={true}
                                    />
                                </div>
                                <XCircleIcon
                                    className="w-7 h-7 text-red-600 mt-5 hover:text-red-500 cursor-pointer"
                                    onClick={() => removeSelectedTracking()}
                                />
                            </div>
                            <div className="pt-2 flex flex-row whitespace-nowrap">
                                <button
                                    className="text-sm flex flex-row items-center gap-x-2 cursor-pointer text-accent hover:text-accenthighlight pt-2"
                                    onClick={() =>
                                        setScanAdditionalTrackings(true)
                                    }
                                >
                                    <BsUpcScan className="w-4 h-4" />
                                    Scan Additional Trackings
                                </button>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {scanAdditionalTrackings && (
                    <div className="pt-4">
                        <div>
                            <h2 className="text-base font-semibold pb-2">
                                Additional tracking numbers
                            </h2>
                        </div>
                        <input
                            type="text"
                            id="additional-trackings"
                            value={additionalTrackingsCurrentInput}
                            onChange={additionalTrackingsHandleInputChange}
                            onKeyDown={additionalTrackingsHandleTrackingKeyDown}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                            placeholder="Press here and start scanning."
                        />

                        <div className="grid grid-cols-2 gap-x-5 gap-y-3 pt-3">
                            {additionalTrackings.map((barcode, index) => (
                                <div className="flex flex-row w-full justify-between items-center text-sm">
                                    <div className="flex flex-col">
                                        <div className="flex flex-row items-center">
                                            {barcode.barcode}
                                            <CopyButton
                                                textToCopy={barcode.barcode}
                                            />
                                        </div>
                                        <span className="text-sm text-gray-600">
                                            {barcode.carrier}
                                        </span>
                                    </div>
                                    <TrashIcon
                                        onClick={() =>
                                            additionalTrackingsRemoveBarcode(
                                                barcode.id
                                            )
                                        }
                                        className="w-5 h-5 text-red-500 hover:cursor-pointer hover:text-red-400"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {(selectedClient || clientNotFoundName !== "") &&
                    trackingNumber && (
                        <>
                            <div className="pt-4 space-y-4 pb-2">
                                <div className="h-px w-full bg-gray-200"></div>
                                <h2 className="text-base font-semibold">
                                    Package Contents
                                </h2>
                            </div>
                            {!matchLoading ? (
                                <>
                                    {orders.map((order, index) => (
                                        <div
                                            className={`-mx-4 ${
                                                matchedOrder?.order_id ===
                                                order.order_id
                                                    ? "border-2 border-accent rounded-md bg-gray-100"
                                                    : ""
                                            }`}
                                        >
                                            <div className="flex flex-col mx-4">
                                                <div className="flex justify-between items-center pt-4">
                                                    <div className="flex flex-row items-center space-x-2">
                                                        <span
                                                            className="text-sm font-medium cursor-pointer"
                                                            onClick={() =>
                                                                toggleExpand(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Item #{index + 1}
                                                        </span>
                                                        {!isMatchOrder && (
                                                            <XCircleIcon
                                                                onClick={() =>
                                                                    removeOrder(
                                                                        index
                                                                    )
                                                                }
                                                                className="w-5 h-5 text-red-600 hover:text-red-500"
                                                            />
                                                        )}
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            toggleExpand(index)
                                                        }
                                                    >
                                                        {expandedIndices.includes(
                                                            index
                                                        ) ? (
                                                            <ChevronUpIcon className="h-auto w-5" />
                                                        ) : (
                                                            <ChevronDownIcon className="h-auto w-5" />
                                                        )}
                                                    </button>
                                                </div>
                                                <div
                                                    key={index}
                                                    className="grid grid-cols-2 sm:grid-cols-5 gap-x-10 gap-y-4 pb-4 border-b border-gray-200"
                                                >
                                                    {expandedIndices.includes(
                                                        index
                                                    ) && (
                                                        <>
                                                            <InputField
                                                                label="UPC"
                                                                value={
                                                                    order.upc
                                                                }
                                                                onChange={(e) =>
                                                                    updateOrder(
                                                                        index,
                                                                        "upc",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                placeholder=""
                                                                customIcon={
                                                                    CiBarcode
                                                                }
                                                                handleIconClick={
                                                                    order.upc &&
                                                                    order.upc
                                                                        .length >
                                                                        0
                                                                        ? () =>
                                                                              setSelectedOrderForUpc(
                                                                                  order
                                                                              )
                                                                        : () => {}
                                                                }
                                                            />
                                                            <div className="relative group">
                                                                <InputField
                                                                    label="Item name"
                                                                    value={
                                                                        order.item_name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateOrder(
                                                                            index,
                                                                            "item_name",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder=""
                                                                    disabled={
                                                                        matchedOrder !==
                                                                            null ||
                                                                        isMatchOrder ||
                                                                        isMatchOrder
                                                                    }
                                                                    handleFocus={() =>
                                                                        setFocusedField(
                                                                            {
                                                                                column: "item_name",
                                                                                index,
                                                                            }
                                                                        )
                                                                    }
                                                                    handleBlur={() => {
                                                                        setFocusedField(
                                                                            null
                                                                        );
                                                                    }}
                                                                    loading={isColumnLoading(
                                                                        "item_name"
                                                                    )}
                                                                />
                                                                <HoverExpandedText
                                                                    show={
                                                                        matchedOrder !==
                                                                            null ||
                                                                        isMatchOrder ||
                                                                        isMatchOrder
                                                                    }
                                                                    text={
                                                                        order.item_name
                                                                    }
                                                                />
                                                                <SuggestedNamesDropDown
                                                                    show={
                                                                        focusedField?.index ===
                                                                            index &&
                                                                        order.item_name !==
                                                                            "" &&
                                                                        getSuggestionsForColumn(
                                                                            "item_name"
                                                                        )
                                                                            .length >
                                                                            0 &&
                                                                        focusedField?.column ===
                                                                            "item_name"
                                                                    }
                                                                    itemsList={getSuggestionsForColumn(
                                                                        "item_name"
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                    updateOrder={
                                                                        updateOrder
                                                                    }
                                                                    setIsFocused={
                                                                        setFocusedField
                                                                    }
                                                                    columnName="item_name"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <InputField
                                                                    label="Color"
                                                                    value={
                                                                        order.color
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateOrder(
                                                                            index,
                                                                            "color",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder=""
                                                                    disabled={
                                                                        matchedOrder !==
                                                                            null ||
                                                                        isMatchOrder
                                                                    }
                                                                    handleFocus={() =>
                                                                        setFocusedField(
                                                                            {
                                                                                column: "color",
                                                                                index,
                                                                            }
                                                                        )
                                                                    }
                                                                    handleBlur={() => {
                                                                        setFocusedField(
                                                                            null
                                                                        );
                                                                    }}
                                                                    loading={isColumnLoading(
                                                                        "color"
                                                                    )}
                                                                />
                                                                <SuggestedNamesDropDown
                                                                    show={
                                                                        focusedField?.index ===
                                                                            index &&
                                                                        order.color !==
                                                                            "" &&
                                                                        getSuggestionsForColumn(
                                                                            "color"
                                                                        )
                                                                            .length >
                                                                            0 &&
                                                                        focusedField?.column ===
                                                                            "color"
                                                                    }
                                                                    itemsList={getSuggestionsForColumn(
                                                                        "color"
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                    updateOrder={
                                                                        updateOrder
                                                                    }
                                                                    setIsFocused={
                                                                        setFocusedField
                                                                    }
                                                                    columnName="color"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <InputField
                                                                    label="Size"
                                                                    value={
                                                                        order.size
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateOrder(
                                                                            index,
                                                                            "size",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder=""
                                                                    disabled={
                                                                        matchedOrder !==
                                                                            null ||
                                                                        isMatchOrder
                                                                    }
                                                                    handleFocus={() =>
                                                                        setFocusedField(
                                                                            {
                                                                                column: "size",
                                                                                index,
                                                                            }
                                                                        )
                                                                    }
                                                                    handleBlur={() => {
                                                                        setFocusedField(
                                                                            null
                                                                        );
                                                                    }}
                                                                    loading={isColumnLoading(
                                                                        "size"
                                                                    )}
                                                                />
                                                                <SuggestedNamesDropDown
                                                                    show={
                                                                        focusedField?.index ===
                                                                            index &&
                                                                        order.size !==
                                                                            "" &&
                                                                        getSuggestionsForColumn(
                                                                            "size"
                                                                        )
                                                                            .length >
                                                                            0 &&
                                                                        focusedField?.column ===
                                                                            "size"
                                                                    }
                                                                    itemsList={getSuggestionsForColumn(
                                                                        "size"
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                    updateOrder={
                                                                        updateOrder
                                                                    }
                                                                    setIsFocused={
                                                                        setFocusedField
                                                                    }
                                                                    columnName="size"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <InputField
                                                                    label="Location"
                                                                    value={
                                                                        order.location
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateOrder(
                                                                            index,
                                                                            "location",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder=""
                                                                    handleFocus={() =>
                                                                        setFocusedField(
                                                                            {
                                                                                column: "location",
                                                                                index,
                                                                            }
                                                                        )
                                                                    }
                                                                    handleBlur={() => {
                                                                        setFocusedField(
                                                                            null
                                                                        );
                                                                    }}
                                                                    loading={isColumnLoading(
                                                                        "location"
                                                                    )}
                                                                />
                                                                <SuggestedNamesDropDown
                                                                    show={
                                                                        focusedField?.index ===
                                                                            index &&
                                                                        order.location !==
                                                                            "" &&
                                                                        getSuggestionsForColumn(
                                                                            "location"
                                                                        )
                                                                            .length >
                                                                            0 &&
                                                                        focusedField?.column ===
                                                                            "location"
                                                                    }
                                                                    itemsList={getSuggestionsForColumn(
                                                                        "location"
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                    updateOrder={
                                                                        updateOrder
                                                                    }
                                                                    setIsFocused={
                                                                        setFocusedField
                                                                    }
                                                                    columnName="location"
                                                                />
                                                            </div>
                                                            <div className="relative">
                                                                <InputField
                                                                    label="Supplier"
                                                                    value={
                                                                        order.retailer
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateOrder(
                                                                            index,
                                                                            "retailer",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder=""
                                                                    disabled={
                                                                        matchedOrder !==
                                                                            null ||
                                                                        isMatchOrder
                                                                    }
                                                                    handleFocus={() =>
                                                                        setFocusedField(
                                                                            {
                                                                                column: "retailer",
                                                                                index,
                                                                            }
                                                                        )
                                                                    }
                                                                    handleBlur={() => {
                                                                        setFocusedField(
                                                                            null
                                                                        );
                                                                    }}
                                                                    loading={isColumnLoading(
                                                                        "retailer"
                                                                    )}
                                                                />
                                                                <SuggestedNamesDropDown
                                                                    show={
                                                                        focusedField?.index ===
                                                                            index &&
                                                                        order.retailer !==
                                                                            "" &&
                                                                        getSuggestionsForColumn(
                                                                            "retailer"
                                                                        )
                                                                            .length >
                                                                            0 &&
                                                                        focusedField?.column ===
                                                                            "retailer"
                                                                    }
                                                                    itemsList={getSuggestionsForColumn(
                                                                        "retailer"
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                    updateOrder={
                                                                        updateOrder
                                                                    }
                                                                    setIsFocused={
                                                                        setFocusedField
                                                                    }
                                                                    columnName="retailer"
                                                                />
                                                            </div>
                                                            <InputNumberField
                                                                label="Quantity Received"
                                                                value={String(
                                                                    order.quantity
                                                                )}
                                                                onChange={(e) =>
                                                                    updateOrder(
                                                                        index,
                                                                        "quantity",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                placeholder=""
                                                                disabled={
                                                                    matchedOrder !==
                                                                        null ||
                                                                    isMatchOrder
                                                                }
                                                            />
                                                            <div className="relative">
                                                                <InputField
                                                                    label="Brand SKU"
                                                                    value={
                                                                        order.brand_sku
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        updateOrder(
                                                                            index,
                                                                            "brand_sku",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    placeholder=""
                                                                    disabled={
                                                                        matchedOrder !==
                                                                            null ||
                                                                        isMatchOrder
                                                                    }
                                                                    optional={
                                                                        true
                                                                    }
                                                                    handleFocus={() =>
                                                                        setFocusedField(
                                                                            {
                                                                                column: "brand_sku",
                                                                                index,
                                                                            }
                                                                        )
                                                                    }
                                                                    handleBlur={() => {
                                                                        setFocusedField(
                                                                            null
                                                                        );
                                                                    }}
                                                                    loading={isColumnLoading(
                                                                        "brand_sku"
                                                                    )}
                                                                />
                                                                <SuggestedNamesDropDown
                                                                    show={
                                                                        focusedField?.index ===
                                                                            index &&
                                                                        order.brand_sku !==
                                                                            "" &&
                                                                        getSuggestionsForColumn(
                                                                            "brand_sku"
                                                                        )
                                                                            .length >
                                                                            0 &&
                                                                        focusedField?.column ===
                                                                            "brand_sku"
                                                                    }
                                                                    itemsList={getSuggestionsForColumn(
                                                                        "brand_sku"
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                    updateOrder={
                                                                        updateOrder
                                                                    }
                                                                    setIsFocused={
                                                                        setFocusedField
                                                                    }
                                                                    columnName="brand_sku"
                                                                />
                                                            </div>
                                                            <InputField
                                                                label="Notes"
                                                                value={
                                                                    matchedOrder !==
                                                                    null
                                                                        ? matchedOrder?.user_notes ||
                                                                          ""
                                                                        : order.check_in_notes
                                                                }
                                                                onChange={(e) =>
                                                                    updateOrder(
                                                                        index,
                                                                        "check_in_notes",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                placeholder=""
                                                                optional={true}
                                                                disabled={
                                                                    matchedOrder !==
                                                                        null ||
                                                                    isMatchOrder
                                                                }
                                                            />
                                                            {isMatchOrder &&
                                                                matchedOrder?.order_id !==
                                                                    order.order_id && (
                                                                    <div className="col-span-4">
                                                                        <ActionButton
                                                                            label="Select"
                                                                            handleClick={() =>
                                                                                setMatchedOrder(
                                                                                    order
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {isMatchOrder && (
                                        <div className="pt-2">
                                            <button
                                                className="text-sm flex flex-row items-center gap-x-2 cursor-pointer text-accent hover:text-accenthighlight pt-2"
                                                onClick={() => {
                                                    setIsMatchOrder(false);
                                                    setMatchedOrder(null);
                                                    removeMatchedOrders();
                                                }}
                                            >
                                                <PlusCircleIcon className="w-5 h-5" />
                                                Input custom details
                                            </button>
                                        </div>
                                    )}
                                    {!isMatchOrder && (
                                        <div className="pt-2">
                                            <button
                                                className="text-sm flex flex-row items-center gap-x-2 cursor-pointer text-accent hover:text-accenthighlight pt-2"
                                                onClick={() => addOrder()}
                                            >
                                                <PlusCircleIcon className="w-5 h-5" />
                                                Add Item
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="flex flex-row items-center space-x-4 pt-4">
                                    <p className="text-sm text-gray-700">
                                        Searching For Orders...
                                    </p>
                                    <LoadingWheel />
                                </div>
                            )}

                            {matchedOrder && (
                                <div className="pt-4 space-y-4">
                                    <div className="h-px w-full bg-gray-200"></div>
                                    <h2 className="text-base font-semibold">
                                        Would you like to package and reship
                                        this order now?
                                    </h2>
                                    {matchedOrder.shipping_labels &&
                                    matchedOrder.shipping_labels.length > 0 ? (
                                        <span className="isolate inline-flex rounded-md shadow-sm">
                                            <button
                                                onClick={() =>
                                                    setIsReshippingOrder(true)
                                                }
                                                type="button"
                                                className={`${
                                                    isReshippingOrder
                                                        ? "bg-accent text-white ring-1 ring-inset ring-accent"
                                                        : "bg-white text-gray-900 hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
                                                } relative inline-flex w-24 justify-center items-center rounded-l-md px-3 py-2 text-sm font-semibold focus:z-10`}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                onClick={() =>
                                                    setIsReshippingOrder(false)
                                                }
                                                type="button"
                                                className={`${
                                                    !isReshippingOrder
                                                        ? "bg-accent text-white ring-1 ring-inset ring-accent"
                                                        : "bg-white text-gray-900 hover:bg-gray-50 ring-1 ring-inset ring-gray-300"
                                                } relative inline-flex w-24 justify-center items-center rounded-r-md px-3 py-2 text-sm font-semibold focus:z-10`}
                                            >
                                                No
                                            </button>
                                        </span>
                                    ) : (
                                        <div>
                                            <span className="font-medium text-sm text-gray-600">
                                                Client has not uploaded a label
                                                yet. You cannot ship this item
                                                as of now.
                                            </span>
                                        </div>
                                    )}

                                    {isReshippingOrder &&
                                        matchedOrder.shipping_labels &&
                                        matchedOrder.shipping_labels.length >
                                            0 && (
                                            <div className="space-y-2">
                                                {matchedOrder.shipping_labels &&
                                                    matchedOrder.shipping_labels.map(
                                                        (item, index) => (
                                                            <div className="space-x-2">
                                                                <span className="font-semibold text-sm">
                                                                    Shipping
                                                                    Label{" "}
                                                                    {index + 1}:
                                                                </span>
                                                                <button
                                                                    onClick={() =>
                                                                        window.open(
                                                                            `${process.env.REACT_APP_API_BASE_URL}/api/v1/view-pdf?url=${item}`,
                                                                            "_blank"
                                                                        )
                                                                    }
                                                                    className="text-accent font-medium text-sm cursor-pointer"
                                                                >
                                                                    Print
                                                                </button>
                                                            </div>
                                                        )
                                                    )}
                                                <BoxesPopup
                                                    boxes={boxes}
                                                    selectedBoxes={
                                                        selectedBoxes
                                                    }
                                                    setSelectedBoxes={
                                                        setSelectedBoxes
                                                    }
                                                />
                                                <ServicesPopup
                                                    selectedServices={
                                                        selectedServices
                                                    }
                                                    setSelectedServices={
                                                        setSelectedServices
                                                    }
                                                />
                                                <div className="space-y-3">
                                                    <span className="font-semibold text-sm">
                                                        Trackings
                                                    </span>
                                                    <input
                                                        type="text"
                                                        id="tracking"
                                                        value={currentInput}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        onKeyDown={
                                                            handleTrackingKeyDown
                                                        }
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                                                        placeholder="Press here and start scanning."
                                                    />

                                                    <div className="grid grid-cols-2 gap-x-5 gap-y-3">
                                                        {scannedBarcodes.map(
                                                            (
                                                                barcode,
                                                                index
                                                            ) => (
                                                                <div className="flex flex-row w-full justify-between items-center">
                                                                    <div className="flex flex-col">
                                                                        <span className="text-sm">
                                                                            {
                                                                                barcode.barcode
                                                                            }
                                                                        </span>
                                                                        <span className="text-sm text-gray-600">
                                                                            {
                                                                                barcode.dateScanned
                                                                            }{" "}
                                                                            -{" "}
                                                                            {
                                                                                barcode?.carrier
                                                                            }
                                                                        </span>
                                                                        {barcode.error ? (
                                                                            <span className="text-xs text-red-500">
                                                                                {
                                                                                    barcode.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                    {barcode.isLoading ? (
                                                                        <div className="w-5 h-5">
                                                                            <LoadingWheel />{" "}
                                                                        </div>
                                                                    ) : (
                                                                        <TrashIcon
                                                                            onClick={() =>
                                                                                removeBarcode(
                                                                                    barcode.id
                                                                                )
                                                                            }
                                                                            className="w-5 h-5 text-red-500"
                                                                        />
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}

                            <div className="pt-6 space-y-4">
                                <div className="h-px w-full bg-gray-200"></div>
                            </div>
                            <div className="pt-4 flex flex-row-reverse items-center justify-between pb-64">
                                <ActionButton
                                    label={
                                        matchedOrder &&
                                        isReshippingOrder &&
                                        matchedOrder.shipping_labels &&
                                        matchedOrder.shipping_labels.length > 0
                                            ? "Mark Shipped"
                                            : "Check In Order"
                                    }
                                    handleClick={() => handleCreate()}
                                    height={8}
                                    loading={createLoading}
                                    viewDisabled={
                                        isMatchOrder
                                            ? matchedOrder === null
                                            : orders.length < 1
                                    }
                                />
                                <SecondaryButton
                                    label="Clear Fields"
                                    handleClick={() => clearFields()}
                                    height={8}
                                />
                            </div>
                        </>
                    )}
            </div>

            <UpcModal
                openUpcModal={openUpcModal}
                setOpenUpcModal={setOpenUpcModal}
                upc=""
                order={selectedOrderForUpc}
                setOrders={setOrders}
            />
        </div>
    );
}

export default CheckInOrder;
