/** @format */

import { SortConfig } from "../../../../hooks/useSort";
import { apiRequest } from "../../../../utils/api/apiRequest";
import { buildUrl } from "../../../../utils/url/buildUrl";

export const fetchAudits = async (
    searchParams: URLSearchParams,
    sortConfig: SortConfig
) => {
    const baseUrl = `/admin/audits`;
    const params = {
        offset: searchParams.get("offset"),
        limit: searchParams.get("limit"),
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc",
    };

    const url = buildUrl(baseUrl, params);

    return apiRequest(url, "GET");
};
