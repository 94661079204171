/** @format */

import { createElement } from "react";
import { IconType } from "react-icons";
import LoadingWheel from "./loadingWheel";

interface ButtonProps {
    label: string;
    handleClick: any;
    iconDisplayed?:
        | IconType
        | React.ForwardRefExoticComponent<
              Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
                  title?: string;
                  titleId?: string;
              } & React.RefAttributes<SVGSVGElement>
          >;
    height?: number;
    loading?: boolean;
    viewDisabled?: boolean;
}

export function ActionButton({
    label,
    iconDisplayed,
    handleClick,
    height = 7,
    loading = false,
    viewDisabled = false,
}: ButtonProps) {
    return (
        <button
            disabled={loading || viewDisabled}
            onClick={() => handleClick()}
            className={`${
                height === 7
                    ? "h-7 text-xs"
                    : height === 8
                    ? "h-8 text-xs"
                    : "h-9 text-sm"
            } ${
                viewDisabled
                    ? "bg-accentdim "
                    : "bg-accent border-accentdark hover:bg-accenthighlight"
            } px-4 rounded-lg shadow-sm whitespace-nowrap w-fit text-white font-medium flex items-center gap-2  transition-all`}
        >
            {iconDisplayed && !loading ? (
                createElement(iconDisplayed, { className: "w-4 h-4" })
            ) : (
                <>{loading && <LoadingWheel color="white" />}</>
            )}
            {label}
        </button>
    );
}

export function YellowActionButton({
    label,
    iconDisplayed,
    handleClick,
    height = 7,
    loading = false,
    viewDisabled = false,
}: ButtonProps) {
    return (
        <button
            disabled={loading || viewDisabled}
            onClick={() => handleClick()}
            className={`${
                height === 7
                    ? "h-7 text-xs"
                    : height === 8
                    ? "h-8 text-xs"
                    : "h-9 text-sm"
            } ${
                viewDisabled
                    ? "bg-yellow-200 "
                    : "bg-yellow-500 border-yellow-600 hover:bg-yellow-400"
            } px-4 rounded-lg shadow-sm whitespace-nowrap w-fit text-white font-medium flex items-center gap-2  transition-all`}
        >
            {iconDisplayed && !loading ? (
                createElement(iconDisplayed, { className: "w-4 h-4" })
            ) : (
                <>{loading && <LoadingWheel color="white" />}</>
            )}
            {label}
        </button>
    );
}

export function GreenActionButton({
    label,
    iconDisplayed,
    handleClick,
    height = 7,
    loading = false,
    viewDisabled = false,
}: ButtonProps) {
    return (
        <button
            disabled={loading || viewDisabled}
            onClick={() => handleClick()}
            className={`${
                height === 7
                    ? "h-7 text-xs"
                    : height === 8
                    ? "h-8 text-xs"
                    : "h-9 text-sm"
            } ${
                viewDisabled
                    ? "bg-green-200 "
                    : "bg-green-500 border-green-600 hover:bg-green-400"
            } px-4 rounded-lg shadow-sm whitespace-nowrap w-fit text-white font-medium flex items-center gap-2  transition-all`}
        >
            {iconDisplayed && !loading ? (
                createElement(iconDisplayed, { className: "w-4 h-4" })
            ) : (
                <>{loading && <LoadingWheel color="white" />}</>
            )}
            {label}
        </button>
    );
}

export function SecondaryButton({
    label,
    iconDisplayed,
    handleClick,
    height = 7,
    loading = false,
}: ButtonProps) {
    return (
        <button
            onClick={() => handleClick()}
            className={`${
                height === 7
                    ? "h-7 text-xs"
                    : height === 8
                    ? "h-8 text-xs"
                    : "h-9 text-xs"
            } px-4 shadow-sm whitespace-nowrap rounded-md bg-white border border-gray-300 text-gray-800 font-medium flex items-center gap-2 hover:bg-gray-50 hover:border-gray-400 transition-all`}
        >
            {iconDisplayed && !loading ? (
                createElement(iconDisplayed, { className: "w-4 h-4" })
            ) : (
                <>{loading && <LoadingWheel color="black" />}</>
            )}
            {label}
        </button>
    );
}

export function DeleteButton({
    label,
    iconDisplayed,
    handleClick,
    height = 7,
    loading = false,
    viewDisabled = false,
}: ButtonProps) {
    return (
        <button
            disabled={loading || viewDisabled}
            onClick={() => handleClick()}
            className={`${
                height === 7
                    ? "h-7 text-xs"
                    : height === 8
                    ? "h-8 text-sm"
                    : "h-9 text-sm"
            } ${
                viewDisabled ? "bg-red-300" : "bg-red-600 hover:bg-red-500"
            } px-4 rounded-lg text-white font-medium flex items-center gap-2 transition-all`}
        >
            {iconDisplayed && !loading ? (
                createElement(iconDisplayed, { className: "w-4 h-4" })
            ) : (
                <>{loading && <LoadingWheel color="white" />}</>
            )}
            {label}
        </button>
    );
}
