/** @format */

import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Order, ShipmentQuantities } from "../../../../models/order";
import { useState } from "react";
import RequestShippingDetails from "./requestShippingDetailsModal";
import { RequestOutboundShipment } from "../inventory-data/request-shipment/requestOutboundShipmentModal";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import RequestFBAShipments from "./confirmRequestShipmentsModal";

const tabs = [
    {
        name: "Request Outbound Shipment",
        value: "request_outbound_shipment",
        href: "#",
        current: false,
    },
    {
        name: "Request Shipping Details",
        value: "request_shipping_details",
        href: "#",
        current: false,
    },
    {
        name: "Request FBA Shipments",
        value: "request_fba_shipments",
        href: "#",
        current: true,
    },
];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

function RequestActionsModal({
    openRequestActionsModal,
    setOpenRequestActionsModal,
    selectedOrders,
    setSelectedOrders,
    handleRefetch,
}: {
    openRequestActionsModal: boolean;
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrders: Order[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    handleRefetch: () => void;
}) {
    const [selectedAction, setSelectedAction] = useState(
        "request_outbound_shipment"
    );

    return (
        <Dialog
            open={openRequestActionsModal}
            onClose={() => setOpenRequestActionsModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() =>
                                    setOpenRequestActionsModal(false)
                                }
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>
                        {/* 
                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Request Actions
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div> */}

                        <div>
                            <div className="grid grid-cols-1 sm:hidden">
                                <select
                                    defaultValue={"Request Shipping Details"}
                                    onChange={() =>
                                        setSelectedAction(
                                            tabs.find((tab) => tab.current)
                                                ?.value || ""
                                        )
                                    }
                                    aria-label="Select a tab"
                                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                                >
                                    {tabs.map((tab) => (
                                        <option key={tab.name}>
                                            {tab.name}
                                        </option>
                                    ))}
                                </select>
                                <ChevronDownIcon
                                    aria-hidden="true"
                                    className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                                />
                            </div>
                            <div className="hidden sm:block">
                                <nav
                                    aria-label="Tabs"
                                    className="flex space-x-4"
                                >
                                    {tabs.map((tab) => (
                                        <button
                                            key={tab.name}
                                            className={classNames(
                                                tab.value === selectedAction
                                                    ? "bg-gray-100 text-gray-700"
                                                    : "text-gray-500 hover:text-gray-700",
                                                "rounded-md px-3 py-2 text-sm font-medium"
                                            )}
                                            onClick={() =>
                                                setSelectedAction(tab.value)
                                            }
                                        >
                                            {tab.name}
                                        </button>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        {selectedAction === "request_shipping_details" ? (
                            <RequestShippingDetails
                                setOpenRequestActionsModal={
                                    setOpenRequestActionsModal
                                }
                                selectedOrders={selectedOrders}
                                setSelectedOrders={setSelectedOrders}
                            />
                        ) : selectedAction === "request_outbound_shipment" ? (
                            <>
                                {selectedOrders.every(
                                    (o) => o.fulfilled_by === "merchant"
                                ) ? (
                                    <RequestOutboundShipment
                                        selectedOrders={selectedOrders}
                                        setSelectedOrders={setSelectedOrders}
                                        openRequestActionsModal={
                                            openRequestActionsModal
                                        }
                                        setOpenRequestActionsModal={
                                            setOpenRequestActionsModal
                                        }
                                        handleRefetch={handleRefetch}
                                    />
                                ) : (
                                    <span className="text-sm text-gray-700 pt-10 flex flex-row items-center gap-x-2">
                                        <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                                        You cannot request an outbound shipment
                                        unless all selected orders are fulfilled
                                        by merchant.
                                    </span>
                                )}
                            </>
                        ) : (
                            <RequestFBAShipments
                                setOpenRequestActionsModal={
                                    setOpenRequestActionsModal
                                }
                            />
                        )}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default RequestActionsModal;
