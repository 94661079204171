import Cookies from "js-cookie";
import { Order } from "../../../models/order";
import { ScannedBarcode } from "../../../models/scannedBarcode";
import { buildUrl } from "../../../utils/url/buildUrl";
import { SortConfig } from "../../../hooks/useSort";
import { ShipmentService } from "../../../models/shipments";
import { apiRequest } from "../../../utils/api/apiRequest";
import { Box } from "../../../models/adminDashboard";


export const createShipment = async (
    user_id: string | undefined | null,
    name: string,
    date: string,
    notes: string,
    boxes: {name: string, quantity: number, price: number}[],
    ordersPayload: { orderId: string; quantityShipped: string; }[],
    scannedBarcodes: ScannedBarcode[],
    selectedServices: ShipmentService[]
) => {

    try {
        const jsonBody = {
            "user_id": user_id,
            "name": name,
            "date": date,
            "notes": notes,
            "boxes": boxes,
            "inventory_orders": ordersPayload,
            "trackings": scannedBarcodes,
            "services": selectedServices
        }
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();

        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const deleteShipment = async (
    shipment_id: string | undefined
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/` + shipment_id,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const fetchAllShipmentTrackings = async (user_id: string | undefined | null, offset: number, limit: number, sortConfig: SortConfig) => {
    try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/trackings`;
        const params = {
            user_id: user_id ? user_id : null,
            offset: String(offset),
            limit: limit && limit != 0 ? String(limit) : "10",
            sort_key: sortConfig ? sortConfig.key : "created_at",
            sort_order: sortConfig ? sortConfig.order : "desc"
        };

        const url = buildUrl(baseUrl, params);
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const updateRequestedShipmentStatus = async (
    shipment_id: string | undefined,
    boxes: Box[],
    services: ShipmentService[]
) => {

    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/requested/` + shipment_id,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({boxes, services})
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const updateShipment = async (
    shipment_id: string | undefined,
    name: string,
    date: string,
    notes: string,
    boxes: Box[],
    services: ShipmentService[],
    mark_shipped: boolean
) => {
    return apiRequest("/shipments/" + shipment_id, "PUT", {name, date, notes, boxes, services, mark_shipped})
}

export const updateRequestedShipmentStatusToPending = async (
    shipment_id: string | undefined
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/shipments/requested/status/` + shipment_id,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
}; 

export const addTrackingToShipment = async (shipment_id: string, tracking: ScannedBarcode) => {
    return apiRequest(`/shipments/trackings/${shipment_id}`, "POST", {tracking})
}

export const deleteTrackingFromShipment = async (shipment_id: string, tracking_id: string) => {
    return apiRequest(`/shipments/${shipment_id}/trackings`, "DELETE", {tracking_id})
}