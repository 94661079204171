/** @format */

"use client";

import { useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { requestFBAShipments } from "../api/inventoryApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import LoadingWheel from "../../../../components/loadingWheel";
import { ActionButton } from "../../../../components/buttons";

interface RequestFBAShipmentsProps {
    setOpenRequestActionsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RequestFBAShipments({
    setOpenRequestActionsModal,
}: RequestFBAShipmentsProps) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);
    const handleRequest = async () => {
        setLoading(true);
        const data = await requestFBAShipments();
        if (data.status === "success") {
            setOpenRequestActionsModal(false);
            showNotification("Requested FBA shipments.", "", "success");
        } else {
            showNotification(
                "Failed to request shipments.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <>
            <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4 -mx-1">
                <div className="sm:flex sm:items-start pt-4">
                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                        <CheckCircleIcon
                            aria-hidden="true"
                            className="size-6 text-green-600"
                        />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold text-gray-900"
                        >
                            Request FBA Shipments
                        </DialogTitle>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Confirm you'd like the warehouse to package and
                                ship out your current inventory.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-row-reverse">
                <ActionButton
                    label="Request shipments"
                    handleClick={() => handleRequest()}
                    height={9}
                    loading={loading}
                />
            </div>
        </>
    );
}
