/** @format */

import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/outline";
import { ClientOrderCount } from "../../../../models/clients";
import { useClient } from "../../../../utils/client/clientContext";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import AdminSideBar from "../../../../components/admin/sideBar";
import Header from "../../../../components/header";
import TabSelector from "../../../../components/tabSelector";
import CheckInOrder from "../markReceivedOrder";
import ReceivedOrders from "../receivedOrders";

const tabs = (
    orderCount: ClientOrderCount | null | undefined // Pass the count as an argument
) => [
    {
        name: "Mark Received",
        href: "/admin/received/mark-received",
        icon: ClipboardDocumentCheckIcon,
        value: "check-in",
    },
    {
        name: `Needs Checked In ${
            orderCount ? `(${orderCount.received.toLocaleString()})` : ""
        }`,
        href: "/admin/received/needs-check-in",
        icon: ClipboardDocumentCheckIcon,
        value: "received",
    },
    {
        name: `Checked In ${
            orderCount ? `(${orderCount.checked_in.toLocaleString()})` : ""
        }`,
        href: "/admin/received/checked-in",
        icon: ClipboardDocumentCheckIcon,
        value: "checked-in",
    },
];

function AdminCheckedInPage() {
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const [searchParams] = useSearchParams();

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center pb-1">
                    <span className="text-2xl font-bold">
                        Check In Packages
                    </span>
                </div>

                <TabSelector tabs={tabs(orderCount)} />

                <ReceivedOrders selectedTab="checked-in" />
            </div>
        </div>
    );
}

export default AdminCheckedInPage;
