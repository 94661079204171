/** @format */

import { useEffect, useState } from "react";
import ClientSideBar from "../../../../components/client/sideBar";
import { SmallSelectorWithNavigate } from "../../../../components/dropdowns/smallSelector";
import Header from "../../../../components/header";
import { fetchShippingDetailRequests } from "../api/shippingDetailsApi";
import { useNotification } from "../../../../utils/notification/notificationContext";
import Results from "../../../../models/results";
import { ShippingDetailsRequest } from "../../../../models/shippingDetailsRequest";
import ShippingDetailsTable, {
    initialShippingDetailsColumnOrder,
} from "../../../../tables/shippingDetailsTable";
import LoadingWheel from "../../../../components/loadingWheel";
import { useSort } from "../../../../hooks/useSort";
import TabSelector from "../../../../components/tabSelector";
import { pendingTabs } from "../requested/page";
import { useClient } from "../../../../utils/client/clientContext";

function ShippingDetailsPage() {
    const { showNotification } = useNotification();
    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const { orderCount } = useClient();

    const [shippingDetailRequests, setShippingDetailRequests] = useState<
        ShippingDetailsRequest[]
    >([]);
    const [results, setResults] = useState<Results | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchShippingDetailRequests();
        if (data.status === "success") {
            setShippingDetailRequests(data.data.requests);
            setResults(data.data.results);
        } else {
            showNotification("Failed to fetch data.", data.message, "error");
        }
        setLoading(false);
    };

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };
    useEffect(() => {
        fetchData();
    }, [refetchData]);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto sm:px-10 px-4">
                <div className="">
                    <Header />
                </div>

                <div className="pt-10 flex flex-col overflow-x-auto min-h-screen">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">Outbound</span>
                        <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                            {orderCount?.shipping_details}
                        </span>
                    </div>

                    <TabSelector tabs={pendingTabs(orderCount)} />

                    <div className="flex flex-row items-start space-x-4 pt-4">
                        <SmallSelectorWithNavigate
                            selectorData={[
                                {
                                    id: 1,
                                    name: "Shipped",
                                    value: "/outbound/shipped",
                                },
                                {
                                    id: 2,
                                    name: "Requested",
                                    value: "/outbound/requested",
                                },
                                {
                                    id: 4,
                                    name: "Trackings",
                                    value: "/outbound/trackings",
                                },
                            ]}
                        />
                    </div>
                    {!loading ? (
                        <ShippingDetailsTable
                            shippingDetails={shippingDetailRequests}
                            results={results}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            emptyTableText="You have no shipping names added yet."
                            columnOrder={initialShippingDetailsColumnOrder}
                            // handleDelete={handleDelete}
                        />
                    ) : (
                        <div className="flex flex-grow items-center pb-44 justify-center">
                            {loading ? (
                                <LoadingWheel />
                            ) : (
                                <span className="text-sm text-gray-500">
                                    User has no inbound orders
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ShippingDetailsPage;
