import dayjs, { Dayjs } from "dayjs";
import utc from 'dayjs/plugin/utc';
import { format, toZonedTime } from "date-fns-tz";
dayjs.extend(utc);

export const formatDateFromDb = (dateString: string): string => {
    return dayjs.utc(dateString).format('MM/DD/YYYY');
};

export const formatDateFromObject = (date: Dayjs): string => {
    return date.format('MM/DD/YYYY')
};

export const convertToDayjsFromString = (dateString: string): Dayjs => {
    return dayjs(dateString, 'YYYY/MM/DD');
};

// export const formatDateFromDb = (dateString: string): string => {
//     return dayjs(dateString).format('MM/DD/YYYY');
// };

export function convertDateToESTNoTime(timestamp: string) {
    const date = new Date(timestamp);
  
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York', // EST timezone
        year: 'numeric',              // 'numeric' instead of 'string'
        month: '2-digit',
        day: '2-digit',
      };
    
    // Get the formatted date string
    const formattedDate = date.toLocaleString('en-US', options);
  
    return formattedDate.replace(',', '');
  }

export function convertDateToEST(timestamp: string) {
    const date = new Date(timestamp);
  
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York', // EST timezone
        year: 'numeric',              // 'numeric' instead of 'string'
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,                 // 12-hour format
      };
    
    // Get the formatted date string
    const formattedDate = date.toLocaleString('en-US', options);
  
    return formattedDate.replace(',', '');
  }

  export const convertToESTV2 = (
    utcTimestamp: string | null | undefined, 
    includeTime: boolean = false, 
    includeSeconds: boolean = true
) => {
    // Parse the UTC timestamp to a JavaScript Date object
    if (!utcTimestamp) {
        return "";
    }
    const utcDate = new Date(utcTimestamp);

    const options: Intl.DateTimeFormatOptions = includeTime
        ? {
              timeZone: "America/New_York", // EST time zone
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              ...(includeSeconds ? { second: "2-digit" } : {}), // Conditionally add seconds
              hour12: true, // For 12-hour format; set to false for 24-hour format
          }
        : {
              timeZone: "America/New_York", // EST time zone
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          };

    // Format the date to EST in the desired format
    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(utcDate);
};
