/** @format */

import { Helmet } from "react-helmet";
import Header from "../../components/website/header";
import FeaturesTable from "./featuresTable";
import { motion } from "framer-motion";
import { useNotification } from "../../utils/notification/notificationContext";
import { useState } from "react";
import LoadingWheel from "../../components/loadingWheel";
import {
    CheckCircleIcon,
    ChevronUpDownIcon,
    CheckIcon,
} from "@heroicons/react/20/solid";
import Footer from "../../components/website/footer";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { shipmentSizes } from "./requestQuote";
import { sendContactRequest } from "./api/contactApi";

function PostcardPage() {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [fullName, setFullName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");

    const [selectedShipmentSize, setSelectedShipmentSize] = useState(
        shipmentSizes[0]
    );

    const [submittedForm, setSubmittedForm] = useState(false);

    const clearFields = () => {
        setFullName("");
        setCompanyName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
    };

    const handleContact = async () => {
        setLoading(true);
        const data = await sendContactRequest(
            fullName,
            companyName,
            email,
            phoneNumber,
            message,
            selectedShipmentSize.name,
            "Contact form - Postcard"
        );
        if (data.status === "success") {
            showNotification("Succesfully sent contact request", "", "success");
            setSubmittedForm(true);
            clearFields();
        } else {
            showNotification(
                "Failed sending contact request. Try again.",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <div className="scroll-smooth">
            <Helmet>
                <script
                    async
                    src="https://www.googletagmanager.com/gtag/js?id=AW-16730636970"
                ></script>
                <script>
                    {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'AW-16730636970');
          `}
                </script>
            </Helmet>
            <Header />
            <div className="mx-auto max-w-5xl px-5 pt-32 space-y-2 lg:px-8">
                <h3 className="font-semibold text-xl">
                    You've got our post card, now what?
                </h3>
                <p className="text-base text-gray-700">
                    Enter your details and we'll reach out to you and make sure
                    we're a good fit!
                </p>
            </div>

            <motion.div
                className="grid grid-cols-2 gap-x-8 gap-y-6 sm:grid-cols-2 px-5 pt-4 mx-auto max-w-5xl lg:px-8 "
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
            >
                <div>
                    <div className="flex flex-row items-center">
                        <label
                            htmlFor="first-name"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                            Name
                        </label>
                        <div className="text-red-500">*</div>
                    </div>
                    <div className="mt-2.5">
                        <input
                            id="full-name"
                            name="full-name"
                            type="text"
                            autoComplete="given-name"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="">
                    <label
                        htmlFor="company-name"
                        className="block text-sm font-semibold leading-6 text-gray-900"
                    >
                        Company Name
                    </label>
                    <div className="mt-2.5">
                        <input
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-2 col-span-2">
                    <div className="flex flex-row items-center">
                        <label
                            htmlFor="email"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                            Email
                        </label>

                        <div className="text-red-500">*</div>
                    </div>
                    <div className="mt-2.5">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="">
                    <div className="flex flex-row items-center">
                        <label
                            htmlFor="phone-number"
                            className="block text-sm font-semibold leading-6 text-gray-900"
                        >
                            Phone number
                        </label>

                        <div className="text-red-500">*</div>
                    </div>
                    <div className="mt-2.5">
                        <input
                            id="phone-number"
                            name="phone-number"
                            type="tel"
                            autoComplete="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="mt-0.5">
                    <Listbox
                        value={selectedShipmentSize}
                        onChange={setSelectedShipmentSize}
                    >
                        <Label className="block text-sm font-semibold leading-6 text-gray-900">
                            Units / month
                        </Label>
                        <div className="relative mt-2">
                            <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                                <span className="block truncate">
                                    {selectedShipmentSize.name}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                        aria-hidden="true"
                                        className="h-5 w-5 text-gray-400"
                                    />
                                </span>
                            </ListboxButton>

                            <ListboxOptions
                                transition
                                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                            >
                                {shipmentSizes.map((shipmentSize) => (
                                    <ListboxOption
                                        key={shipmentSize.id}
                                        value={shipmentSize}
                                        className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                                    >
                                        <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                            {shipmentSize.name}
                                        </span>

                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="h-5 w-5"
                                            />
                                        </span>
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </div>
                    </Listbox>
                </div>
                <div className="col-span-2">
                    <button
                        disabled={loading || submittedForm}
                        onClick={handleContact}
                        className={`${
                            submittedForm
                                ? "bg-green-200 text-green-500 cursor-default"
                                : "bg-accent text-white hover:bg-accenthighlight"
                        } rounded-md items-center flex flex-row justify-center w-full h-10 text-center text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent`}
                    >
                        {submittedForm ? (
                            <CheckCircleIcon className="w-5 h-5 items-center flex" />
                        ) : !loading ? (
                            <>Send</>
                        ) : (
                            <LoadingWheel size="small" color="white" />
                        )}
                    </button>
                </div>
            </motion.div>

            <div className="mx-auto max-w-5xl px-5 lg:px-8 pt-10">
                <motion.img
                    alt="Product screenshot"
                    src="/img-pallets.jpeg"
                    className="rounded-xl shadow-md ring-1 ring-gray-400/10"
                    initial={{ opacity: 0, y: 100 }} // Start position: right side
                    whileInView={{ opacity: 1, y: 0 }} // Animation on scroll into view
                    transition={{ duration: 0.8 }} // Duration of the animation
                    viewport={{ once: true }} // Animate once on scroll
                />
            </div>

            <div className="mx-auto max-w-5xl px-5 lg:px-8 pt-10">
                <FeaturesTable />
            </div>

            <div className="mx-auto max-w-5xl px-5 lg:px-8 pt-10 space-y-4 sm:space-y-4 sm:flex sm:flex-col pb-2">
                <div className="bg-gray-50 shadow-md h-32 flex flex-col justify-between px-4 py-3 rounded-md sm:w-full">
                    <div>
                        <h4 className="text-base font-medium">
                            Dashboard Management
                        </h4>
                        <p className="text-sm text-gray-600">
                            Real-Time Shipment Tracking & Management.
                        </p>
                    </div>
                    <div>
                        <a
                            href="https://dashboard.urvafreight.com/login"
                            className="text-accent text-sm font-sem"
                        >
                            Access Dashboard
                        </a>
                    </div>
                </div>

                <motion.img
                    alt="Product screenshot"
                    src="/img-dashboard2.png"
                    className="rounded-xl shadow-md ring-1 ring-gray-400/10"
                    initial={{ opacity: 0, y: 100 }} // Start position: right side
                    whileInView={{ opacity: 1, y: 0 }} // Animation on scroll into view
                    transition={{ duration: 0.8 }} // Duration of the animation
                    viewport={{ once: true }} // Animate once on scroll
                />

                <div className="bg-gray-50 shadow-md h-32 flex flex-col justify-between px-4 py-3 rounded-md sm:w-full">
                    <div>
                        <h4 className="text-base font-medium">
                            Explore Our Pricing
                        </h4>
                        <p className="text-sm text-gray-600">
                            Simple, competitive, and transparent.
                        </p>
                    </div>
                    <div>
                        <a
                            href="/pricing"
                            className="text-accent text-sm font-sem"
                        >
                            View Pricing
                        </a>
                    </div>
                </div>

                <motion.img
                    alt="Product screenshot"
                    src="/boxes_image.jpeg"
                    className="rounded-xl shadow-md ring-1 ring-gray-400/10"
                    initial={{ opacity: 0, y: 100 }} // Start position: right side
                    whileInView={{ opacity: 1, y: 0 }} // Animation on scroll into view
                    transition={{ duration: 0.8 }} // Duration of the animation
                    viewport={{ once: true }} // Animate once on scroll
                />

                <div className="bg-gray-50 shadow-md h-32 flex flex-col justify-between px-4 py-3 rounded-md sm:w-full">
                    <div>
                        <h4 className="text-base font-medium">
                            Visit Our Home Page
                        </h4>
                        <p className="text-sm text-gray-600">
                            Discover our services and solutions.
                        </p>
                    </div>
                    <div>
                        <a href="/" className="text-accent text-sm font-sem">
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PostcardPage;
