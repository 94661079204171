/** @format */

import { useSearchParams } from "react-router-dom";
import { TrackingInfo } from "../models/trackings";
import { TrackingStatus } from "../models/order";
import { useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { SortLoading } from "../components/loadingWheel";
import { TrashIcon } from "@heroicons/react/24/outline";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { FormSubmission } from "../pages/admin/leads/form-submissions/page";
import { AuditLog } from "../pages/admin/audits/page";
import { HoverExpandedText } from "../pages/admin/received/markReceivedOrder";

export const initialAuditsColumnOrder = [
    {
        key: "created_at",
        label: "Date modified",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "modified_by",
        label: "Modified by",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "table_name",
        label: "Table",
        isSortable: false,
        className: "sm:min-w-10 min-w-10 pl-4",
    },
    {
        key: "operation",
        label: "Operation",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "row_id",
        label: "Row modified",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "old_values",
        label: "Old values",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "new_values",
        label: "new_values",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
    {
        key: "description",
        label: "Description",
        isSortable: false,
        className: "sm:min-w-32 min-w-32 pl-4",
    },
];

interface AuditsTableProps {
    audits: AuditLog[];
    results: Results;
    selectedAudits: AuditLog[];
    setSelectedAudits: React.Dispatch<React.SetStateAction<AuditLog[]>>;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder: Column[];
    hiddenHeaders: string[];
}

function AuditsTable({
    audits,
    results,
    selectedAudits,
    setSelectedAudits,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    hiddenHeaders,
}: AuditsTableProps) {
    const [searchParams] = useSearchParams();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) => !hiddenHeaders.includes(header.key)
    );

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelectedAudits(audits);
        } else {
            setSelectedAudits([]);
        }
    };

    const handleCheckboxChange = (audit: AuditLog) => {
        const auditId = audit.audit_id;
        if (selectedAudits.some((o) => o.audit_id === auditId)) {
            setSelectedAudits(
                selectedAudits.filter((o) => o.audit_id !== auditId)
            );
        } else {
            setSelectedAudits([...selectedAudits, audit]);
        }
    };

    return (
        <div className="flex flex-col pt-3">
            {audits && audits.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.key === "select" ? (
                                                    <input
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={
                                                            handleSelectAll
                                                        }
                                                        checked={
                                                            selectedAudits &&
                                                            audits.length ===
                                                                selectedAudits.length &&
                                                            audits.length > 0
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        {header.label}
                                                        <ColumnSort
                                                            header={header}
                                                            sortConfig={
                                                                sortConfig
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {audits.map(
                                    (audit: AuditLog, index: number) => (
                                        <tr
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {header.key ===
                                                            "select" ? (
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                audit
                                                                            )
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        checked={selectedAudits.some(
                                                                            (
                                                                                o
                                                                            ) =>
                                                                                o.audit_id ===
                                                                                audit.audit_id
                                                                        )}
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "created_at" ? (
                                                                <td className="py-2.5 text-sm whitespace-nowrap">
                                                                    {convertDateToEST(
                                                                        String(
                                                                            audit[
                                                                                header.key as keyof AuditLog
                                                                            ]
                                                                        )
                                                                    )}
                                                                </td>
                                                            ) : false ? (
                                                                <td>
                                                                    <div className="relative group">
                                                                        Show it
                                                                        <HoverExpandedText
                                                                            show={
                                                                                true
                                                                            }
                                                                            text={JSON.stringify(
                                                                                audit[
                                                                                    header.key as keyof AuditLog
                                                                                ]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            ) : header.key ===
                                                              "row_id" ? (
                                                                <td className="text-accent cursor-pointer whitespace-nowrap pl-4">
                                                                    <a
                                                                        href={`/admin/inventory?search=${audit.row_id}`}
                                                                    >
                                                                        {
                                                                            audit.row_id
                                                                        }
                                                                    </a>
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm whitespace-nowrap pl-4"
                                                                >
                                                                    {String(
                                                                        typeof audit[
                                                                            header.key as keyof AuditLog
                                                                        ] ===
                                                                            "object"
                                                                            ? JSON.stringify(
                                                                                  audit[
                                                                                      header.key as keyof AuditLog
                                                                                  ]
                                                                              )
                                                                            : audit[
                                                                                  header.key as keyof AuditLog
                                                                              ]
                                                                    )}
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/audits?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/audits?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}
        </div>
    );
}

export default AuditsTable;
