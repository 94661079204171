/** @format */

import { useEffect, useState } from "react";
import AdminSideBar from "../../../components/admin/sideBar";
import Header from "../../../components/header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotification } from "../../../utils/notification/notificationContext";
import {
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { useClient } from "../../../utils/client/clientContext";
import { fetchWorklist } from "./api/worklistApi";
import Shipment from "../../../models/shipments";
import { capitalizeFirstLetter } from "../../../utils/strings/string";
import { AssignedTask } from "../../../models/adminDashboard";
import { ShippingDetailsRequest } from "../../../models/shippingDetailsRequest";
import LoadingWheel from "../../../components/loadingWheel";
import ShippingDetailsOrdersModal from "../../client/outbound/shipping-details/shippingDetailsOrdersModal";
import { FbaShipmentRequest } from "../../../models/clients";
import { convertToESTV2 } from "../../../utils/date/date_formatter";

interface RequestedShimentsActions {
    total_count: number;
    data: Shipment[];
}

interface AssignedTasksActions {
    total_count: number;
    data: AssignedTask[];
}

interface ShippingDetailRequestsActions {
    total_count: number;
    data: ShippingDetailsRequest[];
}

interface FbaShipmentsRequestedActions {
    total_count: number;
    data: FbaShipmentRequest[];
}

export default function AdminTodoPage() {
    const { showNotification } = useNotification();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const {
        client,
        setClient,
        clientLoading,
        user_id,
        setUserId,
        orderCount,
        handleOrderCountRefetch,
    } = useClient();

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    const [requestedShipmentsActions, setRequestedShipmentsActions] =
        useState<RequestedShimentsActions | null>(null);
    const [assignedTasksActions, setAssignedTasksActions] =
        useState<AssignedTasksActions | null>(null);
    const [shippingDetailRequestsActions, setShippingDetailRequestsActions] =
        useState<ShippingDetailRequestsActions | null>(null);
    const [fbaShipmentsRequestedActions, setFbaShipmentsRequestedActions] =
        useState<FbaShipmentsRequestedActions | null>(null);

    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchWorklist();
        if (data.status === "success") {
            setRequestedShipmentsActions(data.data.requested_shipments);
            setAssignedTasksActions(data.data.assigned_tasks);
            setShippingDetailRequestsActions(
                data.data.shipping_detail_requests
            );
            setFbaShipmentsRequestedActions(data.data.fba_requested_shipments);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
    };

    const displayedItems = isExpanded
        ? requestedShipmentsActions?.data
        : requestedShipmentsActions?.data.slice(0, 5);

    const [selectedShippingDetails, setSelectedShippingDetails] =
        useState<ShippingDetailsRequest | null>(null);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10 pb-20">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center">
                    <span className="text-2xl font-bold">Worklist</span>
                </div>

                <div className="grid grid-cols-2 pt-4 gap-x-10 gap-y-10">
                    <div>
                        <div className="flex flex-row items-center space-x-4">
                            <h3 className="text-lg font-medium">
                                Requested Shipments
                            </h3>
                            <div className="border border-accent px-1.5 py-1 text-accent text-xs font-medium rounded-xl">
                                {requestedShipmentsActions?.total_count} Actions
                            </div>
                            <div className="border border-gray-300 px-1.5 py-1 text-gray-500 text-xs font-medium rounded-xl">
                                Showing{" "}
                                {requestedShipmentsActions &&
                                    requestedShipmentsActions.data.length}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 w-full gap-y-2 pt-2">
                            {!loading ? (
                                <>
                                    {requestedShipmentsActions &&
                                        requestedShipmentsActions.data.length <
                                            1 && (
                                            <p className="text-sm text-gray-600">
                                                No assigned admin tasks
                                            </p>
                                        )}
                                    {requestedShipmentsActions &&
                                        displayedItems &&
                                        displayedItems.map((action, index) => (
                                            <div
                                                key={action.shipment_id}
                                                className={`relative ${
                                                    !isExpanded && index === 4
                                                        ? ""
                                                        : ""
                                                }`}
                                            >
                                                <div
                                                    onClick={() => {
                                                        setUserId(
                                                            action.user_id
                                                        );
                                                        navigate(
                                                            "/admin/outbound/requested"
                                                        );
                                                    }}
                                                    className={`flex flex-row items-center justify-between hover:bg-gray-100 py-1 rounded-md cursor-pointer ${
                                                        !isExpanded &&
                                                        index === 4
                                                            ? "after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:h-12 after:bg-gradient-to-t after:from-white"
                                                            : ""
                                                    }`}
                                                >
                                                    <div className="flex flex-col">
                                                        <h4 className="font-medium text-gray-700">
                                                            {capitalizeFirstLetter(
                                                                action.fulfilled_by
                                                            )}{" "}
                                                            fulfilled requested
                                                            shipment
                                                        </h4>
                                                        <p className="text-gray-600 text-sm">
                                                            By{" "}
                                                            {action.full_name}{" "}
                                                            at{" "}
                                                            {convertToESTV2(
                                                                action.created_at,
                                                                true,
                                                                false
                                                            )}
                                                        </p>
                                                        <p className="text-gray-600 text-sm">
                                                            Contains{" "}
                                                            {
                                                                action.total_quantity_shipped
                                                            }{" "}
                                                            units
                                                        </p>
                                                    </div>
                                                    <ChevronRightIcon className="text-gray-700 h-5 w-5" />
                                                </div>
                                                <div className="pt-2">
                                                    <div className="w-full h-px bg-gray-300"></div>
                                                </div>
                                                {index === 4 && !isExpanded && (
                                                    <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
                                                        <button
                                                            onClick={
                                                                toggleExpand
                                                            }
                                                            className="translate-y-5 bg-gray-200 rounded-full shadow-lg p-2 hover:scale-105 transition"
                                                        >
                                                            <ChevronDownIcon className="h-5 w-5 text-gray-700" />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    {isExpanded && (
                                        <button
                                            onClick={toggleExpand}
                                            className="mt-4 flex items-center justify-center bg-gray-100 rounded-full shadow-sm p-2 hover:scale-105 transition"
                                        >
                                            <ChevronUpIcon className="h-5 w-5 text-gray-700" />
                                        </button>
                                    )}
                                </>
                            ) : (
                                <div className="pt-10 flex flex-row items-center justify-center">
                                    <LoadingWheel />
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-row items-center space-x-4">
                            <h3 className="text-lg font-medium">
                                Requested FBA Shipments
                            </h3>
                            <div className="border border-accent px-1.5 py-1 text-accent text-xs font-medium rounded-xl">
                                {fbaShipmentsRequestedActions?.total_count}{" "}
                                Actions
                            </div>
                        </div>
                        <div className="grid grid-cols-1 w-full gap-y-2 pt-2">
                            {fbaShipmentsRequestedActions &&
                                fbaShipmentsRequestedActions.data.length <
                                    1 && (
                                    <p className="text-sm text-gray-600">
                                        No fba shipments requested
                                    </p>
                                )}
                            {fbaShipmentsRequestedActions &&
                                fbaShipmentsRequestedActions.data.map(
                                    (action) => (
                                        <div key={action.user_id}>
                                            <div
                                                onClick={() => {
                                                    setUserId(action.user_id);
                                                    navigate(
                                                        `/admin/inventory?user_id=${action.user_id}`
                                                    );
                                                }}
                                                className="flex flex-row items-center justify-between hover:bg-gray-100 py-1 rounded-md cursor-pointer"
                                            >
                                                <div className="flex flex-col">
                                                    <h4 className="font-medium text-gray-700">
                                                        FBA shipments requested
                                                    </h4>
                                                    <p className="text-gray-600 text-sm">
                                                        By {action.full_name} at{" "}
                                                        {convertToESTV2(
                                                            action.shipments_requested_at,
                                                            true,
                                                            false
                                                        )}
                                                    </p>
                                                    <p className="text-gray-600 text-sm">
                                                        {action.order_count}{" "}
                                                        orders in inventory
                                                    </p>
                                                </div>
                                                <ChevronRightIcon className="text-gray-700 h-5 w-5" />
                                            </div>
                                            <div className="pt-2">
                                                <div className="w-full h-px bg-gray-300"></div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>

                    <div>
                        <div className="flex flex-row items-center space-x-4">
                            <h3 className="text-lg font-medium">
                                Shipment Detail Requests
                            </h3>
                            <div className="border border-accent px-1.5 py-1 text-accent text-xs font-medium rounded-xl">
                                {shippingDetailRequestsActions?.total_count}{" "}
                                Actions
                            </div>
                        </div>
                        <div className="grid grid-cols-1 w-full gap-y-2 pt-2">
                            {shippingDetailRequestsActions &&
                                shippingDetailRequestsActions.data.length <
                                    1 && (
                                    <p className="text-sm text-gray-600">
                                        No shipping details requested
                                    </p>
                                )}
                            {shippingDetailRequestsActions &&
                                shippingDetailRequestsActions.data.map(
                                    (action) => (
                                        <div
                                            key={
                                                action.requested_shipping_details_id
                                            }
                                        >
                                            <div
                                                onClick={() =>
                                                    setSelectedShippingDetails(
                                                        action
                                                    )
                                                }
                                                className="flex flex-row items-center justify-between hover:bg-gray-100 py-1 rounded-md cursor-pointer"
                                            >
                                                <div className="flex flex-col">
                                                    <h4 className="font-medium text-gray-700">
                                                        Shipment details
                                                        requested
                                                    </h4>
                                                    <p className="text-gray-600 text-sm">
                                                        By {action.full_name} at{" "}
                                                        {convertToESTV2(
                                                            action.created_at,
                                                            true,
                                                            false
                                                        )}
                                                    </p>
                                                    <p className="text-gray-600 text-sm">
                                                        {action.client_notes}
                                                    </p>
                                                </div>
                                                <ChevronRightIcon className="text-gray-700 h-5 w-5" />
                                            </div>
                                            <div className="pt-2">
                                                <div className="w-full h-px bg-gray-300"></div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>

                    <div>
                        <div className="flex flex-row items-center space-x-4">
                            <h3 className="text-lg font-medium">
                                Admin Worklist
                            </h3>
                            <div className="border border-accent px-1.5 py-1 text-accent text-xs font-medium rounded-xl">
                                {assignedTasksActions?.total_count} Actions
                            </div>
                        </div>
                        <div className="grid grid-cols-1 w-full gap-y-2 pt-2">
                            {assignedTasksActions &&
                                assignedTasksActions.data.length < 1 && (
                                    <p className="text-sm text-gray-600">
                                        No assigned admin tasks
                                    </p>
                                )}
                            {assignedTasksActions &&
                                assignedTasksActions.data.map((action) => (
                                    <div key={action.task_id}>
                                        <div className="flex flex-row items-center justify-between hover:bg-gray-100 py-1 rounded-md cursor-pointer">
                                            <div className="flex flex-col">
                                                <h4 className="font-medium text-gray-700">
                                                    {capitalizeFirstLetter(
                                                        action.task
                                                    )}{" "}
                                                    fulfilled requested shipment
                                                </h4>
                                                <p className="text-gray-600 text-sm">
                                                    By Anthony
                                                </p>
                                                {/* <p className="text-gray-600 text-sm">
                                                    Contains{" "}
                                                    {
                                                        action.total_quantity_shipped
                                                    }{" "}
                                                    units
                                                </p> */}
                                            </div>
                                            <ChevronRightIcon className="text-gray-700 h-5 w-5" />
                                        </div>
                                        <div className="pt-2">
                                            <div className="w-full h-px bg-gray-300"></div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            <ShippingDetailsOrdersModal
                shippingDetails={selectedShippingDetails}
                setShippingDetails={setSelectedShippingDetails}
                userId={selectedShippingDetails?.user_id}
                handleClientRefetch={handleRefetch}
            />
        </div>
    );
}
