/** @format */

import { PlusIcon } from "@heroicons/react/24/outline";
import AdminSideBar from "../../../../components/admin/sideBar";
import { ActionButton } from "../../../../components/buttons";
import Header from "../../../../components/header";
import { useClient } from "../../../../utils/client/clientContext";
import { AddContactModal } from "./addContactModal";
import { useEffect, useState } from "react";
import { fetchContacts } from "../api/leadsApi";
import { useSearchParams } from "react-router-dom";
import { useSort } from "../../../../hooks/useSort";
import Results from "../../../../models/results";
import { useNotification } from "../../../../utils/notification/notificationContext";
import ContactsTable, {
    initialContactsColumnOrder,
} from "../../../../tables/contactsTable";
import LoadingWheel from "../../../../components/loadingWheel";

export interface LeadContact {
    contact_id: string;
    created_at: string;
    name: string;
    email: string;
    notes: string;
}

function AdminContactsPage() {
    const {
        client,
        setClient,
        user_id,
        setUserId,
        orderCount,
        handleOrderCountRefetch,
    } = useClient();

    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [contacts, setContacts] = useState<LeadContact[]>([]);
    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const data = await fetchContacts(searchParams, sortConfig);
        if (data.status === "success") {
            setContacts(data.data.contacts);
            setResults(data.data.results);
        } else {
            showNotification("Failed to fetch data", data.message, "error");
        }
        setLoading(false);
    };

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => prev + 1);
    };

    useEffect(() => {
        fetchData();
    }, [refetchData]);

    const [openAddContactModal, setOpenAddContactModal] = useState(false);

    return (
        <div className="flex flex-row h-screen">
            <AdminSideBar user_id={user_id} orderCount={orderCount} />

            <div className="flex flex-col flex-grow sm:px-10 px-4 overflow-x-auto pt-10">
                <Header user_id={user_id} />

                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                        <span className="text-2xl font-bold">Contacts</span>
                        {/* <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                            {orderCount?.inventory}
                        </span> */}
                    </div>
                    <div>
                        <ActionButton
                            label="Add contact"
                            handleClick={() => setOpenAddContactModal(true)}
                            height={8}
                            iconDisplayed={PlusIcon}
                        />
                    </div>
                </div>
                <div className="pt-6"></div>

                {!loading ? (
                    <ContactsTable
                        contacts={contacts}
                        results={results}
                        sortLoading={sortLoading}
                        sortConfig={sortConfig}
                        toggleSort={toggleSort}
                        handleRefetch={handleRefetch}
                        emptyTableText="No records found"
                        columnOrder={initialContactsColumnOrder}
                        hiddenHeaders={[]}
                    />
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <LoadingWheel />
                    </div>
                )}
            </div>

            <AddContactModal
                openAddContactModal={openAddContactModal}
                setOpenAddContactModal={setOpenAddContactModal}
                handleRefetch={handleRefetch}
            />
        </div>
    );
}

export default AdminContactsPage;
