/** @format */
import { useEffect, useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import Results from "../models/results";
import Shipment, { ShipmentType } from "../models/shipments";
import { useSearchParams } from "react-router-dom";
import useScrollTranslation from "../hooks/useScrollTranslation";
import { downloadShipmentPdf } from "../pages/admin/api/labelsApi";
import { useNotification } from "../utils/notification/notificationContext";
import useCellSelection from "../hooks/useCellSelection";
import LoadingWheel, { SortLoading } from "../components/loadingWheel";
import ColumnSort from "../utils/sort/columnSort";
import { ShipmentStatusTag } from "../components/statusTags";
import { convertToESTV2, formatDateFromDb } from "../utils/date/date_formatter";
import { formatMoney } from "../utils/price/formatMoney";
import { PaginationResults } from "../utils/pagination/paginationResults";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import OutboundOrdersModal from "../pages/admin/clients/client-data/shipments/outbound-shipment/outboundOrders";
import ClientOutboundOrdersModal from "../pages/client/outbound/outboundOrdersModal";
import { Column } from "./models";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { ClientType } from "../utils/clientTypes/actions";
import { useClient } from "../utils/client/clientContext";

export const initialShipmentsColumnOrder = [
    {
        key: "status",
        label: "Status",
        className: "sm:min-w-32 min-w-32",
        isSortable: false,
    },
    {
        key: "date_shipped",
        label: "Date shipped",
        className: "sm:min-w-32 min-w-28",
        isSortable: true,
    },
    {
        key: "date_requested",
        label: "Date requested",
        className: "sm:min-w-32 min-w-28",
        isSortable: true,
    },
    {
        key: "full_name",
        label: "Client",
        className: "sm:min-w-44 min-w-44",
        isSortable: true,
    },
    {
        key: "name",
        label: "Shipment Name",
        className: "sm:min-w-44 min-w-44",
        isSortable: true,
    },
    {
        key: "total_quantity_shipped",
        label: "Total Quantity",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
        isSummable: true,
    },
    {
        key: "shipment_value",
        label: "Shipment Value",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
        isSummable: true,
    },
    {
        key: "notes",
        label: "Notes",
        className: "sm:min-w-32 min-w-28",
        isSortable: false,
    },
    {
        key: "actions",
        label: "Actions",
        className: "text-right",
        isSortable: false,
    },
];

interface ShipmentsTableProps {
    shipmentType: ShipmentType;
    shipments: Shipment[];
    setShipments: React.Dispatch<React.SetStateAction<Shipment[]>>;
    results: Results;
    selectedShipments: Shipment[];
    setSelectedShipments: React.Dispatch<React.SetStateAction<Shipment[]>>;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    client_type: ClientType;
    setTotalSum: React.Dispatch<React.SetStateAction<string>>;
    columnOrder: Column[];
    hiddenHeaders: string[];
}

function ShipmentsTable({
    shipmentType,
    shipments,
    setShipments,
    results,
    selectedShipments,
    setSelectedShipments,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    client_type,
    setTotalSum,
    columnOrder,
    hiddenHeaders,
}: ShipmentsTableProps) {
    const { showNotification } = useNotification();

    const { user_id } = useClient();

    const isAdmin = () => window.location.href.includes("admin");

    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const [openOutboundOrdersModal, setOpenOutboundOrdersModal] =
        useState(false);

    const [selectedShipment, setSelectedShipment] = useState<Shipment | null>(
        null
    );

    useEffect(() => {
        if (selectedShipment) {
            setOpenOutboundOrdersModal(true);
        }
    }, [selectedShipment]);

    useEffect(() => {
        if (!openOutboundOrdersModal) {
            setTimeout(() => {
                setSelectedShipment(null);
            }, 200);
        }
    }, [openOutboundOrdersModal]);

    const handleDownload = async (
        event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>,
        shipment_id: string
    ) => {
        event.stopPropagation();
        const success = await downloadShipmentPdf(shipment_id);
        if (success) {
        } else {
            showNotification("Failed to download pdf");
        }
    };

    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) =>
            !hiddenHeaders.includes(header.key) &&
            (header.key !== "full_name" || (isAdmin() && !user_id))
    );

    const getCellValue = (
        rowIndex: number,
        columnIndex: number,
        data: any[]
    ) => {
        const order = data[rowIndex];
        if (displayedHeaders) {
            const key = displayedHeaders[columnIndex].key;
            if (key) return order[key];
            return 0;
        }
    };

    const {
        selectedCells,
        handleSingleClick,
        handleMouseDown,
        handleMouseEnter,
        handleMouseUp,
        sumSelectedQuantities,
    } = useCellSelection(
        [],
        getCellValue,
        displayedHeaders
            ?.map((column, index) => (column.isSummable ? index : -1))
            .filter((index) => index !== -1) || []
    );

    useEffect(() => {
        const formattedTotal = sumSelectedQuantities
            .filter(({ sum }) => sum > 0)
            .map(({ columnIndex, sum }) =>
                displayedHeaders &&
                ["unit_cost", "list_price", "shipment_value"].includes(
                    displayedHeaders[columnIndex].key
                )
                    ? formatMoney(sum)
                    : sum.toString()
            )
            .join(", ");

        setTotalSum(formattedTotal);
    }, [sumSelectedQuantities]);

    return (
        <div className="flex flex-col pt-3">
            <>
                {!loading && shipments && shipments.length > 0 ? (
                    <>
                        <div
                            ref={scrollContainerRef}
                            className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                                sortLoading
                                    ? "pointer-events-none overflow-hidden"
                                    : ""
                            }`}
                        >
                            <SortLoading
                                sortLoading={sortLoading}
                                scrollOffset={scrollOffset}
                            />
                            <table
                                onMouseUp={handleMouseUp}
                                className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full"
                            >
                                <thead className="sticky top-0 bg-white z-10">
                                    <tr className="text-left text-sm">
                                        {displayedHeaders &&
                                            displayedHeaders.map((header) => (
                                                <>
                                                    {header.key ===
                                                    "actions" ? (
                                                        <>
                                                            {shipmentType ===
                                                                "requested" && (
                                                                <th className="text-right">
                                                                    {
                                                                        header.label
                                                                    }
                                                                </th>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <th
                                                            className={`group py-2 cursor-pointer ${
                                                                sortableColumns.includes(
                                                                    header.key
                                                                )
                                                                    ? "hover:bg-gray-100"
                                                                    : ""
                                                            } ${
                                                                header.className
                                                            }`}
                                                            onClick={() =>
                                                                sortableColumns.includes(
                                                                    header.key
                                                                ) &&
                                                                toggleSort(
                                                                    header.key
                                                                )
                                                            }
                                                        >
                                                            {header.label}
                                                            <ColumnSort
                                                                header={header}
                                                                sortConfig={
                                                                    sortConfig
                                                                }
                                                            />
                                                        </th>
                                                    )}
                                                </>
                                            ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipments.map((shipment, rowIndex) => (
                                        <tr
                                            key={shipment.shipment_id}
                                            className={`relative border-t border-gray-200 text-sm text-gray-500 hover:bg-gray-100`}
                                            onClick={() =>
                                                setSelectedShipment(shipment)
                                            }
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header, colIndex) => (
                                                        <>
                                                            {header.key ===
                                                            "status" ? (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                >
                                                                    <ShipmentStatusTag
                                                                        status={
                                                                            shipment.admin_status
                                                                                ? shipment.admin_status
                                                                                : shipment.status
                                                                        }
                                                                    />
                                                                </td>
                                                            ) : header.key ===
                                                              "date_shipped" ? (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                    className="whitespace-nowrap pr-4"
                                                                >
                                                                    {convertToESTV2(
                                                                        shipment.date_shipped,
                                                                        true,
                                                                        false
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "date_requested" ? (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                    className="whitespace-nowrap pr-4"
                                                                >
                                                                    {convertToESTV2(
                                                                        shipment.date_requested,
                                                                        true,
                                                                        false
                                                                    )}
                                                                </td>
                                                            ) : header.isSummable ? (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                    onMouseDown={() =>
                                                                        handleMouseDown(
                                                                            rowIndex,
                                                                            colIndex,
                                                                            shipments
                                                                        )
                                                                    }
                                                                    onMouseEnter={() =>
                                                                        handleMouseEnter(
                                                                            rowIndex,
                                                                            colIndex,
                                                                            shipments
                                                                        )
                                                                    }
                                                                    onClick={() =>
                                                                        handleSingleClick(
                                                                            rowIndex,
                                                                            colIndex,
                                                                            shipments
                                                                        )
                                                                    }
                                                                    className={`py-2.5 ${
                                                                        selectedCells.some(
                                                                            (
                                                                                cell
                                                                            ) =>
                                                                                cell.rowIndex ===
                                                                                    rowIndex &&
                                                                                cell.columnIndex ===
                                                                                    colIndex
                                                                        )
                                                                            ? "bg-blue-200 select-none"
                                                                            : "select-none"
                                                                    }`}
                                                                >
                                                                    {[
                                                                        "unit_cost",
                                                                        "list_price",
                                                                        "shipment_value",
                                                                    ].includes(
                                                                        String(
                                                                            header.key
                                                                        )
                                                                    ) ? (
                                                                        <>
                                                                            {formatMoney(
                                                                                Number(
                                                                                    shipment[
                                                                                        header.key as keyof Shipment
                                                                                    ]
                                                                                )
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {String(
                                                                                shipment[
                                                                                    header.key as keyof Shipment
                                                                                ]
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "actions" ? (
                                                                <>
                                                                    {shipmentType ===
                                                                        "requested" && (
                                                                        <td
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                handleDownload(
                                                                                    e,
                                                                                    shipment.shipment_id
                                                                                )
                                                                            }
                                                                            className="text-right whitespace-nowrap pr-2 cursor-pointer space-x-2"
                                                                        >
                                                                            <PrinterIcon className="w-5 h-5 text-accent inline-block" />
                                                                        </td>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <td
                                                                    key={`${rowIndex}-${header.key}`}
                                                                    className="py-2.5"
                                                                >
                                                                    {String(
                                                                        shipment[
                                                                            header.key as keyof Shipment
                                                                        ]
                                                                    )}
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {results && (
                            <nav
                                aria-label="Pagination"
                                className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                            >
                                <div className="hidden sm:block">
                                    <div className="flex flex-row items-center space-x-3">
                                        <PaginationResults results={results} />
                                        <LimitSelector />
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-between sm:justify-end">
                                    <PaginationLink
                                        href={(function () {
                                            const params = new URLSearchParams(
                                                searchParams
                                            );
                                            // Set the previous page offset
                                            params.set(
                                                "offset",
                                                calculateOffset(
                                                    searchParams,
                                                    "back"
                                                ).toString()
                                            );
                                            const isAdmin =
                                                window.location.href.includes(
                                                    "admin"
                                                );
                                            return isAdmin
                                                ? `/admin/outbound/${shipmentType}?${params.toString()}`
                                                : `/outbound/${shipmentType}`;
                                        })()}
                                        disabled={getOffset(searchParams) === 0}
                                        text="Previous"
                                    />
                                    <PaginationLink
                                        href={(function () {
                                            const params = new URLSearchParams(
                                                searchParams
                                            );
                                            // Set the next page offset
                                            params.set(
                                                "offset",
                                                calculateOffset(
                                                    searchParams,
                                                    "forward"
                                                ).toString()
                                            );
                                            const isAdmin =
                                                window.location.href.includes(
                                                    "admin"
                                                );
                                            // return `/admin/outbound/${shipmentType}?${params.toString()}`;
                                            return isAdmin
                                                ? `/admin/outbound/${shipmentType}?${params.toString()}`
                                                : `/outbound/${shipmentType}`;
                                        })()}
                                        disabled={
                                            results.showing_to >=
                                            results.total_results
                                        }
                                        text="Next"
                                        className="ml-3"
                                    />
                                </div>
                            </nav>
                        )}
                    </>
                ) : (
                    <div className="flex flex-grow items-center pt-10 justify-center">
                        {loading ? (
                            <LoadingWheel />
                        ) : (
                            <span className="text-sm text-gray-500">
                                No records found.
                            </span>
                        )}
                    </div>
                )}
            </>

            {["client", "client_team_member"].includes(client_type) ? (
                <ClientOutboundOrdersModal
                    shipmentLoaded={selectedShipment}
                    openOutboundOrders={openOutboundOrdersModal}
                    setOpenOutboundOrders={setOpenOutboundOrdersModal}
                    handleRefetch={handleRefetch}
                />
            ) : (
                <OutboundOrdersModal
                    shipment={selectedShipment}
                    openOutboundOrders={openOutboundOrdersModal}
                    setOpenOutboundOrders={setOpenOutboundOrdersModal}
                    handleFullRefetch={handleRefetch}
                    setShipments={setShipments}
                />
            )}
        </div>
    );
}

export default ShipmentsTable;
