/** @format */

import { ShipmentTracking } from "../../models/shipments";
import CopyButton from "../copyButton";

interface ShipmentTrackingsTableProps {
    trackings: ShipmentTracking[];
}

function ShipmentTrackingsTable({ trackings }: ShipmentTrackingsTableProps) {
    return (
        <div>
            <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                <thead>
                    <tr className="text-left text-sm">
                        <th className="py-2 sm:min-w-32 min-w-32 ">
                            Detected Carrier
                        </th>
                        <th className="py-2 sm:min-w-32 min-w-28 whitespace-nowrap">
                            Tracking
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {trackings &&
                        trackings.map((item, index) => (
                            <tr
                                key={index}
                                className="relative border-t border-gray-200 text-sm text-gray-500"
                            >
                                <td className="py-2.5">{item.carrier}</td>
                                <td className="">
                                    <div className="flex flex-row items-center space-x-1">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={item.tracking_url}
                                            className="text-accent"
                                        >
                                            {item.tracking}
                                        </a>
                                        <CopyButton
                                            textToCopy={item.tracking}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default ShipmentTrackingsTable;
