/** @format */
import Cookies from "js-cookie";
import { debounce } from "lodash";
import { Order } from "../../../../models/order";
import Shipment from "../../../../models/shipments";
import { TrackingInfo } from "../../../../models/trackings";
import { buildUrl } from "../../../../utils/url/buildUrl";
import { AdminNotification } from "../../../../models/adminDashboard";
import { DebouncedFunc } from "lodash";

export const fetchDashboardData = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/statistics`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();
        return data;

    } catch (error) {
        return { data: {status: "failed"} }
    }
};

export const updateTax = async (
    tax_rate: string,
) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account/tax`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({tax_rate})
            }
        );

        const data = await response.json()
        return data;
        
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const removeClientNotification = async (notificationId: string, deleteAll?: boolean) => {
    try {
        const jsonBody = deleteAll ? {deleteAll: true} : {}
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications/` +
                notificationId,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const fetchClientNotifications = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/notifications`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Cookies.get("jwt"),
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (error) {
        return { data: { status: "failed", message: String(error) } };
    }
};

export const searchData = debounce(async (
    searchText: string,
    user_id: string | undefined,
    page: string | null,
    sub_page: string | null,
    setData: React.Dispatch<React.SetStateAction<Order[]>> | React.Dispatch<React.SetStateAction<Shipment[]>> | React.Dispatch<React.SetStateAction<TrackingInfo[]>> | React.Dispatch<React.SetStateAction<AdminNotification[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    handleRefetch: (fromSearch?: any) => void
) => {
    if (searchText.trim() === "") {
        handleRefetch(true)
        return;
    }

    try {
        setLoading(true)

        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/search`;
        const params = {
            searchText: searchText,
            user_id: user_id ? user_id : "",
            page: page,
            sub_page: sub_page
        };

        const url = buildUrl(baseUrl, params);

        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );
        const data = await response.json();
        if (data.status === "success") {
            setData(data.data)
        }
    } catch (error) {

    } finally {
        setLoading(false)
    }
}, 300);

// type SearchDataFunction = DebouncedFunc<(
//     searchText: string,
//     user_id: string | undefined | null,
//     page: string | null,
//     sub_page: string | null,
//     setData: any,
//     setLoading: React.Dispatch<React.SetStateAction<boolean>>,
//     handleRefetch: () => void,
//     notFirstSearch: React.MutableRefObject<boolean>
// ) => Promise<void>> & { controller?: AbortController | null };

// export const searchData: SearchDataFunction = debounce(async (
//     searchText,
//     user_id,
//     page,
//     sub_page,
//     setData,
//     setLoading,
//     handleRefetch,
//     notFirstSearch
// ) => {
//     if (searchText.trim() === "" && notFirstSearch.current) {
//         // console.log(notFirstSearch.current)
//         handleRefetch(true);
//         return;
//     }

//     notFirstSearch.current = true

//     if (searchData.controller) {
//         searchData.controller.abort();
//     }

//     searchData.controller = new AbortController();
//     const { signal } = searchData.controller;

//     try {
//         setLoading(true);

//         const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/search`;
//         const params = {
//             searchText: searchText,
//             user_id: user_id ? user_id : "",
//             page: page,
//             sub_page: sub_page
//         };


//         const url = buildUrl(baseUrl, params);

//         const response = await fetch(url, {
//             method: "GET",
//             headers: {
//                 "Authorization": "Bearer " + Cookies.get("jwt")
//             },
//             signal
//         });

//         // Only proceed if the request was not aborted
//         if (!signal.aborted) {
//             const data = await response.json();
//             if (data.status === "success") {
//                 setData(data.data);
//             }
//         }
//     } catch (error) {
//         if (error instanceof Error) {
//             if (error.name !== "AbortError") {
//                 console.error("An error occurred:", error.message);
//             }
//         } else {
//             console.error("An unknown error occurred:", error);
//         }
//     } finally {
//         setLoading(false);
//     }
// }, 300);

// searchData.controller = null;