/** @format */

import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { ScannedBarcode } from "./models/scannedBarcode";
import { cleanTrackingNumber } from "./utils/carriers/cleanTrackingNumber";
import { v4 as uuidv4 } from "uuid";

export default function BarcodeTest() {
    const [scannedBarcodes, setScannedBarcodes] = useState<ScannedBarcode[]>(
        []
    );
    const [currentInput, setCurrentInput] = useState<string>("");
    const lastKeyTimeRef = useRef<number>(0); // Track the time of the last keystroke
    const DELAY_MS = 10; // Threshold to detect rapid input (barcode scanner)

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const currentTime = Date.now();

        // Check if input is from a barcode scanner (rapid input)
        if (currentTime - lastKeyTimeRef.current < DELAY_MS) {
            if (value.trim()) {
                // Simulate pressing "Enter" automatically for barcode scanner input
                handleSubmit(value.trim());
            }
        }

        // Update the last keystroke time
        lastKeyTimeRef.current = currentTime;
        setCurrentInput(value);
    };

    const handleTrackingKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && currentInput.trim()) {
            e.preventDefault();
            handleSubmit(currentInput.trim());
        }
    };

    const handleSubmit = (input: string) => {
        const [trackingNumber, carrier] = cleanTrackingNumber(input);

        const newScannedBarcode: ScannedBarcode = {
            id: uuidv4(), // Generate a unique ID
            barcode: trackingNumber,
            dateScanned: new Date().toLocaleString(),
            isLoading: false, // Set loading to true initially
            carrier: carrier,
        };

        setScannedBarcodes((prev) => [...prev, newScannedBarcode]);
        setCurrentInput(""); // Clear input
    };

    return (
        <div className="pt-10">
            <input
                type="text"
                id="tracking"
                value={currentInput}
                onChange={handleInputChange}
                onKeyDown={handleTrackingKeyDown}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                placeholder="Press here and start scanning."
            />
            <div className="grid grid-cols-2 gap-x-5 gap-y-3">
                {scannedBarcodes.map((barcode, index) => (
                    <div
                        key={index}
                        className="flex flex-row w-full justify-between items-center"
                    >
                        <div className="flex flex-col">
                            <span className="text-sm">{barcode.barcode}</span>
                            <span className="text-sm text-gray-600">
                                {barcode.dateScanned} - {barcode?.carrier}
                            </span>
                            {barcode.error ? (
                                <span className="text-xs text-red-500">
                                    {barcode.error}
                                </span>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
