/** @format */

import { useEffect, useState } from "react";
import { InputField } from "../../../components/inputField";
import LoadingWheel from "../../../components/loadingWheel";
import { updateNotificationsPreferences } from "./api/settingsApi";
import { useNotification } from "../../../utils/notification/notificationContext";
import {
    EmailNotification,
    NotificationOption,
} from "../../../models/adminDashboard";

function ClientManageNotifications({
    notifications,
    setNotifications,
    discordWebhook,
    handleRefetch,
}: {
    notifications: NotificationOption[];
    setNotifications: React.Dispatch<
        React.SetStateAction<NotificationOption[]>
    >;
    discordWebhook: string;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const handleEdit = async () => {
        setLoading(true);
        const data = await updateNotificationsPreferences(
            notifications,
            updatedDiscordWebhook
        );
        if (data.status === "success") {
            showNotification("Updated notification preferences", "", "success");
            handleRefetch();
        } else {
            showNotification("Failed operation.", data.message, "error");
        }
        setLoading(false);
    };

    const toggleNotification = (index: number, type: string) => {
        setShowSave(true);
        setNotifications((prevNotifications) => {
            const updatedNotifications = [...prevNotifications];
            if (type === "discord") {
                updatedNotifications[index].notify_discord =
                    !updatedNotifications[index].notify_discord;
            } else if (type === "email") {
                updatedNotifications[index].notify_email =
                    !updatedNotifications[index].notify_email;
            }
            return updatedNotifications;
        });
    };

    const [updatedDiscordWebhook, setUpdatedDiscordWebhook] = useState("");
    useEffect(() => {
        setUpdatedDiscordWebhook(discordWebhook);
    }, [discordWebhook]);

    return (
        <div className="bg-white border border-gray-200 shadow-sm sm:rounded-lg lg:w-full">
            <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Manage notifications
                </h3>

                <div className="sm:col-span-full pt-1">
                    <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Discord Webhook
                    </label>
                    <div className="mt-2">
                        <input
                            id="discord_webhook"
                            name="discord_webhook"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent sm:text-sm sm:leading-6"
                            value={updatedDiscordWebhook}
                            onChange={(e) => {
                                setShowSave(true);
                                setUpdatedDiscordWebhook(e.target.value);
                            }}
                            placeholder="https://discord.com/api/webhooks"
                        />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row lg:items-end lg:space-x-4"></div>
                <h3 className="text-sm font-semibold leading-6 text-gray-900 pt-3">
                    Manage notifications
                </h3>

                {/* <div className="grid grid-cols-2 gap-x-6 gap-y-2 pt-1">
                    {notifications.map((option) => (
                        <div
                            id={option.id}
                            className="flex flex-row items-center space-x-2"
                        >
                            <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => toggleNotify(option.id)}
                                checked={option.notify}
                            />
                            <span className="text-sm text-gray-700">
                                {option.name}
                            </span>
                        </div>
                    ))}
                </div> */}

                <div className="grid grid-cols-2 gap-x-6 gap-y-2 pt-1">
                    {notifications.map((notification, index) => (
                        <div key={index} className="flex flex-col space-y-1">
                            <span className="text-sm text-gray-700 font-semibold">
                                {notification.name}
                            </span>
                            <div className="flex flex-row items-center space-x-4">
                                {/* Discord Notification Toggle */}
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() =>
                                            toggleNotification(index, "discord")
                                        }
                                        checked={notification.notify_discord}
                                    />
                                    <span className="text-sm text-gray-700">
                                        Discord
                                    </span>
                                </label>

                                {/* Email Notification Toggle */}
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-accent focus:ring-0 focus:ring-transparent ring-0 focus:ring-offset-0 focus:outline transition-all"
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={() =>
                                            toggleNotification(index, "email")
                                        }
                                        checked={notification.notify_email}
                                    />
                                    <span className="text-sm text-gray-700">
                                        Email
                                    </span>
                                </label>
                            </div>
                        </div>
                    ))}
                </div>

                {showSave && (
                    <div className="pt-4 flex flex-row-reverse">
                        <button
                            disabled={loading}
                            onClick={() => handleEdit()}
                            className="mt-3 lg:w-44 w-full items-center justify-center rounded-md bg-accent px-3 h-9 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                        >
                            {loading ? (
                                <LoadingWheel color="white" size="small" />
                            ) : (
                                <>Save</>
                            )}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
export default ClientManageNotifications;
