import { apiRequest } from "../../../utils/api/apiRequest";
import { buildUrl } from "../../../utils/url/buildUrl";

export const fetchClockEvents = async (employee_id: string, searchParams: URLSearchParams) => {
    const baseUrl = `/admin/employees/clock-events`;
    const params = {
        offset: searchParams.get('offset'),
        limit: searchParams.get('limit'),
        employee_id: employee_id === "all" || employee_id === "0" ? null : employee_id,
    };

    const url = buildUrl(baseUrl, params);
    return apiRequest(url, "GET")
}

export const fetchEmployees = async () => {
    return apiRequest('/admin/employees', "GET")
};

export const addEmployee = async (name: string, email: string) => {
    return apiRequest('/admin/employees', "POST", {name})
}

export const removeEmployee = async (employee_id: string) => {
    return apiRequest(`/admin/employees/${employee_id}`, "DELETE")
}

export const clockInEmployee = async (employee_id: string, timestamp: string) => {
    return apiRequest(`/admin/employees/${employee_id}/clock-in`, "POST", {timestamp})
}

export const clockOutEmployee = async (employee_id: string, timestamp: string) => {
    return apiRequest(`/admin/employees/${employee_id}/clock-out`, "PUT", {timestamp})
}

export const deleteClockEvent = async (event_id: string) => {
    return apiRequest(`/admin/employees/clock-events/${event_id}`, "DELETE")
}

export const updateClockEventPaymentStatus = async (event_ids: string[], is_paid: boolean) => {
    return apiRequest("/admin/employees/clock-events/payment-status", "PUT", {event_ids, is_paid})
}
