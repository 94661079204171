import { SortConfig } from "../../../hooks/useSort";
import { Box } from "../../../models/adminDashboard";
import { ReceivedOrder } from "../../../models/order";
import { ScannedBarcode } from "../../../models/scannedBarcode";
import { ShipmentService } from "../../../models/shipments";
import { apiRequest } from "../../../utils/api/apiRequest"
import { buildUrl } from "../../../utils/url/buildUrl";

export const fetchClientReceivedOrders = async (
    user_id: string | null | undefined,
    offset?: number,
    limit?: number | null,
    sortConfig?: SortConfig,
    status?: string
) => {
    const baseUrl = `/admin/received`;
    const params = {
        user_id: user_id ? user_id : null,
        offset: offset ? String(offset) : "",
        limit: limit && limit != 0 ? String(limit) : "-1",
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc",
        status: status ? status : null
    };
    const url = buildUrl(baseUrl, params);

    return apiRequest(url, "GET")
}

export const createReceivedOrder = async (
    user_id: string | null, order_name: string | null, order_id: string | null, tracking_number: string, carrier: string,
    orders: ReceivedOrder[], is_reshipping_order: boolean, boxes: Box[], services: ShipmentService[], shelf: string | null,
    scannedBarcodes: ScannedBarcode[], received_trackings: ScannedBarcode[]
) => {
    const payload: {
        user_id: string | null;
        order_id?: string | null;
        order_name?: string | null;
        tracking_number: string;
        carrier: string;
        is_reshipping_order: boolean;
        boxes: Box[];
        services: ShipmentService[];
        orders?: ReceivedOrder[]; 
        shelf?: string | null
        trackings?: ScannedBarcode[]
        received_trackings?: ScannedBarcode[]
    } = {
        user_id,
        tracking_number,
        carrier,
        is_reshipping_order,
        boxes,
        services,
        received_trackings
    };
    if (order_id === null) {
        payload.orders = orders;
        payload.order_name = order_name
    } else {
        payload.order_id = order_id
        payload.shelf = shelf
        payload.trackings = scannedBarcodes
    }
    return apiRequest("/admin/received", "POST", payload)
}

export const deleteReceivedOrders = async (received_order_ids: string[]) => {
    return apiRequest("/admin/received", "DELETE", {received_order_ids})
}

export const matchTrackingToOrder = async (user_id: string, tracking_number: string) => {
    return apiRequest("/admin/received/match-tracking", "POST", {user_id, tracking_number})
}

export const updateReceivedOrderStatus = async (received_order_ids: string[], check_in: boolean) => {
    return apiRequest("/admin/received/status", "PUT", {received_order_ids, check_in})
}

export const updateReceivedOrderNotes = async (received_order_ids: string[], notes: string) => {
    return apiRequest("/admin/received/notes", "PUT", {received_order_ids, notes})
}

export const retrieveUpcData = async (upc: string) => {
    return apiRequest(`/admin/received/upc/${upc}`, "GET")
}