/** @format */

import { useState, useEffect } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import dayjs, { Dayjs } from "dayjs";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { InputField, InputTextField } from "../../../../components/inputField";
import { ActionButton } from "../../../../components/buttons";
import { createContact } from "../api/leadsApi";

export function AddContactModal({
    openAddContactModal,
    setOpenAddContactModal,
    handleRefetch,
}: {
    openAddContactModal: boolean;
    setOpenAddContactModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}) {
    const { showNotification } = useNotification();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [notes, setNotes] = useState("");
    const [phone, setPhone] = useState("");

    const handleCreate = async () => {
        setLoading(true);
        const data = await createContact(email, name, notes, phone);
        if (data.status === "success") {
            showNotification("Created new contact.", "", "success");
            setOpenAddContactModal(false);
            handleRefetch();
        } else {
            showNotification(
                "Failed to create new contact",
                data.message,
                "error"
            );
        }
        setLoading(false);
    };

    return (
        <Dialog
            open={openAddContactModal}
            onClose={() => setOpenAddContactModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-4/6 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenAddContactModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Add contact
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 -mx-1">
                            <div className="px-1 space-y-3">
                                <InputField
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <InputField
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <InputField
                                    label="Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <InputTextField
                                    label="Notes"
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                />
                            </div>
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                            <ActionButton
                                label="Create contact"
                                handleClick={() => handleCreate()}
                                height={8}
                                loading={loading}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
