/** @format */

import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatDateFromDb } from "../../utils/date/date_formatter";
import { Order, OrderFulfilledBy } from "../../models/order";
import { MultipleFileUpload } from "../../utils/files/multipleFileUpload";
import { returnInboundUnits } from "../../pages/admin/api/unitsApi";
import { useNotification } from "../../utils/notification/notificationContext";
import { useState } from "react";
import LoadingWheel from "../loadingWheel";
import CopyButton from "../copyButton";
import { FileUpload } from "../../utils/files/fileUpload";
import { InputFileField } from "../inputField";
import {
    deleteShippingLabel,
    uploadReshippingShippingLabel,
} from "../../pages/client/inbound/api/inboundApi";
import { ActionButton } from "../buttons";

function CheckInDatesModal({
    openCheckInDatesModal,
    setOpenCheckInDatesModal,
    selectedOrder,
    filesByOrder,
    handleFileChange,
    removeFile,
    handleRefetch,
    client_type = "admin",
    fulfilledBy,
}: {
    openCheckInDatesModal: boolean;
    setOpenCheckInDatesModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedOrder: Order | null;
    filesByOrder?: {
        [orderId: string]: File[];
    };
    handleFileChange?: (orderId: string, files: File[]) => void;
    removeFile?: (orderId: string, fileToRemove: File) => void;
    handleRefetch?: () => void;
    client_type?: string;
    fulfilledBy?: OrderFulfilledBy;
}) {
    const { showNotification } = useNotification();

    const [loadings, setLoadings] = useState<string[]>([]);

    const handleReturn = async (check_in_id: string) => {
        setLoadings((prev) => [...prev, check_in_id]);
        const data = await returnInboundUnits(check_in_id);
        if (data.status === "success") {
            setOpenCheckInDatesModal(false);
            if (handleRefetch) {
                handleRefetch();
            }
            showNotification("Returned units to inbound", "", "success");
        } else {
            showNotification("Failed to remove units", data.message, "error");
        }
        setLoadings((prev) => prev.filter((id) => id !== check_in_id));
    };

    const [selectedShippingLabelFiles, setSelectedShippingLabelFiles] =
        useState<File[]>([]);

    const [uploadLoading, setUploadLoading] = useState(false);
    const handleUploadLabel = async () => {
        setUploadLoading(true);
        const data = await uploadReshippingShippingLabel(
            selectedOrder?.order_id || "",
            selectedShippingLabelFiles
        );
        if (data.status === "success") {
            setOpenCheckInDatesModal(false);
            if (handleRefetch) handleRefetch();
            showNotification("Uploaded label to order.", "", "success");
        } else {
            showNotification("Failed to upload labels", data.message, "error");
        }
        setUploadLoading(false);
    };

    const [deleteLabelLoadings, setDeleteLabelLoadings] = useState<string[]>(
        []
    );
    const handleDeleteLabel = async (url: string) => {
        setDeleteLabelLoadings((prev) => [...prev, url]);
        const data = await deleteShippingLabel(
            selectedOrder?.order_id || "",
            url
        );
        if (data.status === "success") {
            setOpenCheckInDatesModal(false);
            if (handleRefetch) handleRefetch();
            showNotification("Deleted label.", "", "success");
        } else {
            showNotification("Failed to delete label", data.message, "error");
        }
        setDeleteLabelLoadings((prev) => prev.filter((url) => url !== url));
    };

    const isAdmin = () => window.location.href.includes("admin");

    return (
        <Dialog
            open={openCheckInDatesModal}
            onClose={() => setOpenCheckInDatesModal(false)}
            className="relative z-30"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-30 w-screen">
                <div className="h-4/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-3/5 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenCheckInDatesModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-3"
                        >
                            Check in information
                        </DialogTitle>
                        <div className="text-sm text-gray-700 flex flex-row items-center justify-between">
                            <span>Shipment ID: {selectedOrder?.order_id}</span>
                            <CopyButton
                                textToCopy={selectedOrder?.order_id || ""}
                            />
                        </div>
                        <div className="pb-2"></div>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="flex-1 overflow-y-auto mt-4 mb-4 space-y-4">
                            {selectedOrder &&
                            selectedOrder.shipping_labels &&
                            selectedOrder.shipping_labels.length < 1 &&
                            fulfilledBy === "reshipping" ? (
                                <>
                                    <InputFileField
                                        label="Shipping Label"
                                        value={""}
                                        onChange={() => {}}
                                        placeholder=""
                                        selectedFiles={
                                            selectedShippingLabelFiles
                                        }
                                        setSelectedFiles={
                                            setSelectedShippingLabelFiles
                                        }
                                    />
                                    {selectedShippingLabelFiles.length > 0 && (
                                        <ActionButton
                                            label="Confirm Upload"
                                            handleClick={() =>
                                                handleUploadLabel()
                                            }
                                            loading={uploadLoading}
                                        />
                                    )}
                                </>
                            ) : selectedOrder &&
                              selectedOrder.shipping_labels &&
                              selectedOrder.shipping_labels.length > 0 &&
                              fulfilledBy === "reshipping" ? (
                                <div>
                                    <span className="text-sm font-semibold">
                                        Shipping Labels
                                    </span>
                                    {selectedOrder.shipping_labels.map(
                                        (item, index) => (
                                            <div className="flex flex-row items-center space-x-2">
                                                <span className="text-sm text-gray-600">
                                                    Label {index + 1}:
                                                </span>
                                                <a
                                                    href={item}
                                                    className="text-accent hover:text-accenthighlight font-medium text-sm cursor-pointer pr-3"
                                                >
                                                    Download
                                                </a>
                                                {!isAdmin() && (
                                                    <>
                                                        {!deleteLabelLoadings.includes(
                                                            item
                                                        ) ? (
                                                            <TrashIcon
                                                                onClick={() =>
                                                                    handleDeleteLabel(
                                                                        item
                                                                    )
                                                                }
                                                                className="text-red-500 cursor-pointer hover:text-red-400 h-4 w-4"
                                                            />
                                                        ) : (
                                                            <LoadingWheel />
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <></>
                            )}

                            <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full">
                                <thead>
                                    <tr className="text-left text-sm">
                                        <th className="py-2 sm:min-w-32 min-w-32 ">
                                            Check in date
                                        </th>
                                        <th className="py-2 sm:min-w-32 min-w-28 whitespace-nowrap">
                                            Units
                                        </th>
                                        {client_type === "admin" && (
                                            <th className="py-2 sm:min-w-32 min-w-28 whitespace-nowrap">
                                                Location
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedOrder &&
                                        selectedOrder.check_ins.map(
                                            (item, index) => (
                                                <tr
                                                    key={index}
                                                    className="relative border-t border-gray-200 text-sm text-gray-500"
                                                >
                                                    <td className="py-2.5 flex flex-col">
                                                        <span>
                                                            {formatDateFromDb(
                                                                item.check_in_date
                                                            )}
                                                        </span>
                                                        <span className="text-2xs">
                                                            {new Date(
                                                                item.created_at
                                                            ).toLocaleString(
                                                                undefined,
                                                                {
                                                                    year: "numeric",
                                                                    month: "2-digit",
                                                                    day: "2-digit",
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                    hour12: true,
                                                                }
                                                            )}
                                                        </span>
                                                    </td>
                                                    <td className="">
                                                        {item.quantity_received
                                                            ? item.quantity_received
                                                            : "All"}
                                                    </td>
                                                    {client_type ===
                                                        "admin" && (
                                                        <>
                                                            <td className="">
                                                                {item.location}
                                                            </td>
                                                            <td>
                                                                {loadings.includes(
                                                                    item.check_in_id
                                                                ) ? (
                                                                    <LoadingWheel size="small" />
                                                                ) : (
                                                                    <TrashIcon
                                                                        onClick={() =>
                                                                            handleReturn(
                                                                                item.check_in_id
                                                                            )
                                                                        }
                                                                        className="w-4 h-4 text-red-600 hover:text-red-500"
                                                                    />
                                                                )}
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            )
                                        )}
                                </tbody>
                            </table>

                            <div className="pt-2"></div>
                            <span className="text-sm font-semibold">
                                UPC:{" "}
                                <span className="text-sm text-gray-600 font-normal">
                                    {selectedOrder?.upc}
                                </span>
                            </span>

                            {selectedOrder?.brand_sku && (
                                <>
                                    <div className="pt-2"></div>
                                    <span className="text-sm font-semibold">
                                        Brand SKU:{" "}
                                        <span className="text-sm text-gray-600 font-normal">
                                            {selectedOrder.brand_sku}
                                        </span>
                                    </span>
                                </>
                            )}

                            <div className="pt-2"></div>
                            <span className="text-sm font-semibold">Notes</span>
                            <p className="text-sm text-gray-600">
                                {selectedOrder?.admin_notes}
                            </p>

                            <div className="flex flex-col">
                                {/* <span className="text-sm font-semibold">
                                    Received order
                                </span> */}
                                <a
                                    href={
                                        client_type === "admin"
                                            ? `/admin/received/checked-in?search=${selectedOrder?.order_id}&user_id=${selectedOrder?.user_id}`
                                            : `/received/checked-in?search=${selectedOrder?.order_id}`
                                    }
                                    className="text-sm text-accent"
                                >
                                    View Linked Received Shipment
                                </a>
                            </div>

                            <div className="grid grid-cols-2 gap-10">
                                {selectedOrder &&
                                    selectedOrder.image_urls &&
                                    selectedOrder.image_urls.map((image) => (
                                        <div>
                                            <img
                                                src={image}
                                                className=" aspect-auto w-full"
                                                alt="tracking_image"
                                            />
                                            <a
                                                href={image}
                                                className="text-sm text-blue-600"
                                            >
                                                Save image
                                            </a>
                                        </div>
                                    ))}
                            </div>
                            {filesByOrder && handleFileChange && removeFile && (
                                <>
                                    <h1 className="text-sm font-semibold">
                                        Upload images
                                    </h1>
                                    <MultipleFileUpload
                                        selectedFiles={
                                            selectedOrder && filesByOrder
                                                ? filesByOrder[
                                                      selectedOrder.order_id
                                                  ]
                                                : []
                                        }
                                        handleFileChangeRoot={handleFileChange}
                                        removeFile={removeFile}
                                        orderId={
                                            selectedOrder
                                                ? selectedOrder.order_id
                                                : ""
                                        }
                                        fileType="Images"
                                    />
                                </>
                            )}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default CheckInDatesModal;
