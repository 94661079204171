/** @format */

import { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingWheel from "../../../../../components/loadingWheel";
import { useNotification } from "../../../../../utils/notification/notificationContext";
import { Order } from "../../../../../models/order";
import { FileUpload } from "../../../../../utils/files/fileUpload";
import { fetchLabels, uploadLabel } from "../../api/labelApi";
import {
    ActionButton,
    SecondaryButton,
} from "../../../../../components/buttons";

export function UploadLabelModal({
    openUploadLabelModal,
    setOpenUploadLabelModal,
    handleLabelRefetch,
}: {
    openUploadLabelModal: boolean;
    setOpenUploadLabelModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleLabelRefetch: () => void;
}) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleUpload = async () => {
        setLoading(true);
        const data = await uploadLabel(selectedFile);
        if (data.status === "success") {
            showNotification("Uploaded label", "", "success");
            setOpenUploadLabelModal(false);
            handleLabelRefetch();
            setSelectedFile(null);
        } else {
            showNotification("Failed to upload label", data.message, "error");
        }
        setLoading(false);
    };

    return (
        <div>
            <Dialog
                open={openUploadLabelModal}
                onClose={() => setOpenUploadLabelModal(false)}
                className="relative z-20"
            >
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-20 w-screen">
                    <div className="h-3/5 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="h-3/6 w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="absolute right-0 top-0 pr-4 pt-4">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setOpenUploadLabelModal(false)
                                    }
                                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="h-6 w-6"
                                    />
                                </button>
                            </div>

                            <DialogTitle
                                as="h3"
                                className="text-base font-semibold leading-6 text-gray-900 pb-5"
                            >
                                Upload new label
                            </DialogTitle>
                            <div className="w-full h-px bg-gray-200"></div>

                            <div className="flex-1 overflow-y-auto mt-4 mb-4">
                                <FileUpload
                                    selectedFile={selectedFile}
                                    setSelectedFile={setSelectedFile}
                                    fileType="PDF"
                                />
                            </div>

                            {/* Fixed buttons at the bottom */}
                            <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse justify-between">
                                <ActionButton
                                    label="Upload label"
                                    handleClick={() => handleUpload()}
                                    loading={loading}
                                    height={8}
                                />
                                <SecondaryButton
                                    label="Cancel"
                                    handleClick={() =>
                                        setOpenUploadLabelModal(false)
                                    }
                                    height={8}
                                />
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
