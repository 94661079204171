import { CheckInDate } from "./checkInDate"
import { TrackingInfo } from "./trackings"

export type OrderStatus = "inbound" | "inventory" | "outbound" | "shipments" | "received"
export type TrackingStatus = "inbound" | "inventory" | "outbound"
export type OrderUnitStatus = "fully_received" | "partially_received"
export type OrderFulfilledBy = "amazon" | "merchant" | "reshipping"

export interface CreateOrder {
    item_name: string,
    order_number: string,
    size: string,
    brand_sku: string,
    asin: string,
    retailer: string,
    quantity: string,
    color: string,
    unit_cost: string,
    list_price: string,
    amazon_sku: string,
    notes: string
}

export interface Order {
    user_id: string,
    order_id: string,
    date: string,
    retailer: string,
    order_number: string,
    item_name: string,
    color: string,
    brand_sku: string,
    size: string,
    quantity: number,
    asin: string,
    amazon_sku: string,
    unit_cost: number,
    list_price: number,
    user_notes: string,
    trackings: TrackingInfo[]
    unit_status: OrderUnitStatus,
    status: OrderStatus,
    quantity_received: number
    admin_notes: string
    check_ins: CheckInDate[]
    fulfilled_by: OrderFulfilledBy
    quantity_remaining: number
    quantity_shipped: number
    image_urls: string[]
    shipment_value: number
    shipping_labels: string[]
    upc: string | null
}

export interface OrderTracking {
    tracking: string
}

export interface ShipmentQuantities {
    [key: string]: string;
}

export interface ShelfLocations {
    [key: string]: string;
}

export const fulfilledByOptions = [
    { id: 2, value: "Amazon", fields: ["fulfilled_by", "date", "retailer", "item_name"], },
    { id: 3, value: "Merchant" },
    { id: 4, value: "Reshipping" },
];

export interface ReceivedOrder {
    created_at?: string
    updated_at?: string
    order_id?: string | null; // only for reshipping orders
    received_order_id: string;
    user_id: string;
    date: string;
    retailer: string;
    item_name: string;
    quantity: number | string;
    size: string;
    color: string;
    brand_sku: string;
    check_in_notes: string;
    team_member_notes: string;
    client_notes: string;
    tracking_number: string;
    carrier: string;
    location: string;
    upc: string;
    status: "received" | "checked-in"
    user_notes?: string // only for reshipping orders
    shipping_labels?: string[] // only for reshipping orders
}