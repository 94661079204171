/** @format */

import { useSearchParams } from "react-router-dom";
import { TrackingInfo } from "../models/trackings";
import { TrackingStatus } from "../models/order";
import { useState } from "react";
import { sortableColumns, SortConfig } from "../hooks/useSort";
import useScrollTranslation from "../hooks/useScrollTranslation";
import LoadingWheel, { SortLoading } from "../components/loadingWheel";
import { TrashIcon } from "@heroicons/react/24/outline";
import { convertDateToEST } from "../utils/date/date_formatter";
import ColumnSort from "../utils/sort/columnSort";
import { PaginationResults } from "../utils/pagination/paginationResults";
import Results from "../models/results";
import LimitSelector from "../utils/pagination/limitSelector";
import { PaginationLink } from "../utils/pagination/paginationLink";
import {
    calculateOffset,
    getOffset,
} from "../utils/pagination/calculateOffset";
import { Column } from "./models";
import { SmallCopyButton } from "../components/copyButton";
import { LeadContact } from "../pages/admin/leads/contacts/page";
import { deleteContact } from "../pages/admin/leads/api/leadsApi";
import { useNotification } from "../utils/notification/notificationContext";

export const initialContactsColumnOrder = [
    {
        key: "created_at",
        label: "Date Created",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "name",
        label: "Name",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "email",
        label: "Email",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "phone",
        label: "Phone",
        isSortable: false,
        className: "sm:min-w-32 min-w-32",
    },
    {
        key: "notes",
        label: "Notes",
        isSortable: false,
        className: "",
    },
    {
        key: "delete",
        label: "",
        isSortable: false,
        className: "",
    },
];

interface ContactsTableProps {
    contacts: LeadContact[];
    results: Results;
    sortLoading: boolean;
    sortConfig: SortConfig;
    toggleSort: (key: string) => void;
    handleRefetch: () => void;
    emptyTableText: string;
    columnOrder: Column[];
    hiddenHeaders: string[];
}

function ContactsTable({
    contacts,
    results,
    sortLoading,
    sortConfig,
    toggleSort,
    handleRefetch,
    emptyTableText,
    columnOrder,
    hiddenHeaders,
}: ContactsTableProps) {
    const [searchParams] = useSearchParams();
    const { showNotification } = useNotification();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTrackingId, setSelectedTrackingId] = useState<string | null>(
        ""
    );
    const { scrollContainerRef, scrollOffset } = useScrollTranslation();

    const displayedHeaders = columnOrder.filter(
        (header) => !hiddenHeaders.includes(header.key)
    );

    const [deleteLoadings, setDeleteLoadings] = useState<string[]>([]);
    const handleDelete = async (contact_id: string) => {
        setDeleteLoadings((prev) => [...prev, contact_id]);
        const data = await deleteContact(contact_id);
        if (data.status === "success") {
            handleRefetch();
            showNotification("Deleted contact.", "", "success");
        } else {
            showNotification("Failed to delete contact.", "", "error");
        }
        setDeleteLoadings((prev) => prev.filter((id) => id !== contact_id));
    };

    return (
        <div className="flex flex-col pt-3">
            {contacts && contacts.length > 0 ? (
                <>
                    <div
                        ref={scrollContainerRef}
                        className={`max-h-[650px] 16inch:max-h-[1000px] overflow-y-auto overflow-x-scroll relative scrollbar ${
                            sortLoading
                                ? "pointer-events-none overflow-hidden"
                                : ""
                        }`}
                    >
                        <SortLoading
                            sortLoading={sortLoading}
                            scrollOffset={scrollOffset}
                        />
                        <table className="lg:w-full lg:min-w-full min-w-[600px] sm:min-w-full table-auto">
                            <thead className="sticky top-0 bg-white z-10">
                                <tr className="text-left text-sm">
                                    {displayedHeaders &&
                                        displayedHeaders.map((header) => (
                                            <th
                                                key={header.key}
                                                onClick={() =>
                                                    sortableColumns.includes(
                                                        header.key
                                                    ) && toggleSort(header.key)
                                                }
                                                className={`group py-2 cursor-pointer ${
                                                    sortableColumns.includes(
                                                        header.key
                                                    )
                                                        ? "hover:bg-gray-100"
                                                        : ""
                                                } ${header.className}`}
                                            >
                                                {header.label}
                                                <ColumnSort
                                                    header={header}
                                                    sortConfig={sortConfig}
                                                />
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map(
                                    (contact: LeadContact, index: number) => (
                                        <tr
                                            key={index}
                                            className="relative border-t border-gray-200 text-sm text-gray-500"
                                        >
                                            {displayedHeaders &&
                                                displayedHeaders.map(
                                                    (header: Column) => (
                                                        <>
                                                            {header.key ===
                                                            "created_at" ? (
                                                                <td className="py-2.5 text-sm">
                                                                    {convertDateToEST(
                                                                        contact[
                                                                            header.key as keyof LeadContact
                                                                        ]
                                                                    )}
                                                                </td>
                                                            ) : header.key ===
                                                              "delete" ? (
                                                                <td className="sticky right-0 bottom-0 text-right bg-white cursor-pointer">
                                                                    {!deleteLoadings.includes(
                                                                        contact.contact_id
                                                                    ) ? (
                                                                        <TrashIcon
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    contact.contact_id
                                                                                )
                                                                            }
                                                                            className="h-5 w-5 text-red-500 hover:text-red-400"
                                                                        />
                                                                    ) : (
                                                                        <LoadingWheel />
                                                                    )}
                                                                </td>
                                                            ) : (
                                                                <td
                                                                    key={`${index}-${header.key}`}
                                                                    className="py-2.5 text-sm"
                                                                >
                                                                    {
                                                                        contact[
                                                                            header.key as keyof LeadContact
                                                                        ]
                                                                    }
                                                                </td>
                                                            )}
                                                        </>
                                                    )
                                                )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <nav
                        aria-label="Pagination"
                        className="flex items-center justify-between border-t border-gray-200 bg-white py-3"
                    >
                        <div className="hidden sm:block">
                            <div className="flex flex-row items-center space-x-3">
                                <PaginationResults results={results} />
                                <LimitSelector />
                            </div>
                        </div>
                        <div className="flex flex-1 justify-between sm:justify-end">
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the previous page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "back"
                                        ).toString()
                                    );
                                    return `/admin/leads/contacts?${params.toString()}`;
                                })()}
                                disabled={getOffset(searchParams) === 0}
                                text="Previous"
                            />
                            <PaginationLink
                                href={(function () {
                                    const params = new URLSearchParams(
                                        searchParams
                                    );
                                    // Set the next page offset
                                    params.set(
                                        "offset",
                                        calculateOffset(
                                            searchParams,
                                            "forward"
                                        ).toString()
                                    );
                                    return `/admin/leads/contacts?${params.toString()}`;
                                })()}
                                disabled={
                                    results.showing_to >= results.total_results
                                }
                                text="Next"
                                className="ml-3"
                            />
                        </div>
                    </nav>
                </>
            ) : (
                <div className="flex flex-grow items-center pt-44 justify-center">
                    <span className="text-sm text-gray-500">
                        {emptyTableText}
                    </span>
                </div>
            )}
        </div>
    );
}

export default ContactsTable;
