import { cleanTrackingNumber } from "./cleanTrackingNumber";

export type Carrier = "USPS" | "FedEx" | "UPS" | "DHL" | "Unknown";

export const createTrackingUrl = (trackingNumber: string, courier?: Carrier): string => {
    var carrier: Carrier = "Unknown"
    if (!courier) {
        const [num, carr] = cleanTrackingNumber(trackingNumber)
        carrier = carr as Carrier
    } else {
        carrier = courier
    }
    const trackingUrls: Record<Carrier, string> = {
        USPS: `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`,
        FedEx: `https://www.fedex.com/wtrk/track/?trkNbr=${trackingNumber}&locale=en_US`,
        UPS: `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/trackdetails`,
        DHL: `https://www.dhl.com/global-en/home/tracking.html?tracking-id=${trackingNumber}`,
        Unknown: `https://t.17track.net/en#nums=${trackingNumber}`,
    };

    return trackingUrls[carrier] || "Invalid courier";
};
