/** @format */

"use client";

import { useEffect, useRef, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
    Order,
    ReceivedOrder,
    ShelfLocations,
    ShipmentQuantities,
} from "../../../../models/order";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { ActionButton, SecondaryButton } from "../../../../components/buttons";
import LoadingWheel from "../../../../components/loadingWheel";
import OrdersTable, { getTableHeaders } from "../../../../tables/ordersTable";
import { Column } from "../../../../tables/models";
import ReceivedOrdersTable, {
    initialReceivedOrdersColumnOrder,
} from "../../../../tables/receivedOrdersTable";
import { useSort } from "../../../../hooks/useSort";
import { searchSpecificOrders } from "../../api/clientsApi";
import useDebounce from "../../../../hooks/useDebounce";
import { modifyInboundOrders } from "../../api/unitsApi";
import dayjs, { Dayjs } from "dayjs";
import { formatDateFromObject } from "../../../../utils/date/date_formatter";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { orderTypes } from "../../../../models/data";

interface CheckInOrderModalProps {
    receivedOrders: ReceivedOrder[];
    setOrders: React.Dispatch<React.SetStateAction<ReceivedOrder[]>>;
    openCheckInOrderModal: boolean;
    setOpenCheckInOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefetch: () => void;
}

export default function CheckInOrderModal({
    receivedOrders,
    setOrders,
    openCheckInOrderModal,
    setOpenCheckInOrderModal,
    handleRefetch,
}: CheckInOrderModalProps) {
    const { showNotification } = useNotification();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [loading, setLoading] = useState(false);

    const [receivedTotalSum, setReceivedTotalSum] = useState("");
    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        initialReceivedOrdersColumnOrder
    );
    const [foundOrdersColumnOrder, setFoundOrdersColumnOrder] = useState<
        Column[]
    >(getTableHeaders("inbound"));

    const [keywords, setKeywords] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const debouncedInputValue = useDebounce(inputValue, 300);

    const [foundOrders, setFoundOrders] = useState<Order[]>([]);
    const [selectedFoundOrders, setSelectedFoundOrders] = useState<Order[]>([]);

    const handleSearch = async (searchTerm: string) => {
        setLoading(true);
        const data = await searchSpecificOrders(
            searchTerm,
            receivedOrders.length > 0 ? receivedOrders[0].user_id : "",
            "inbound",
            "units"
        );
        if (data.status === "success") {
            setFoundOrders(data.data);
        }
        setLoading(false);
    };

    const initialFetch = useRef(false);

    useEffect(() => {
        // Only run when modal opens and we have an order
        if (
            openCheckInOrderModal &&
            receivedOrders.length > 0 &&
            !initialFetch.current
        ) {
            // Set initial keyword and perform search
            setKeywords([receivedOrders[0].item_name]);
            handleSearch(receivedOrders[0].item_name);
            initialFetch.current = true;
        } else if (openCheckInOrderModal && initialFetch.current) {
            // For subsequent keyword changes while modal is open
            const searchTerm =
                keywords.length > 0 ? keywords.join(";") : debouncedInputValue;
            handleSearch(searchTerm);
        } else if (!openCheckInOrderModal) {
            if (keywords.length !== 0) {
                setKeywords([]);
            }
            setInputValue("");
            initialFetch.current = false;
        }
    }, [openCheckInOrderModal, receivedOrders, keywords, debouncedInputValue]);

    const handleRemoveKeyword = (index: number) => {
        setKeywords(keywords.filter((_, i) => i !== index));
    };

    const inputRef = useRef<HTMLInputElement>(null);

    const handleAddKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && inputValue.trim()) {
            setKeywords([...keywords, inputValue.trim()]);
            setInputValue("");
        }
    };

    const [filesByOrder, setFilesByOrder] = useState<{
        [orderId: string]: File[];
    }>({});

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const [shelfs, setShelfs] = useState<ShelfLocations>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const handleShelfChange = (orderId: string, value: string) => {
        setShelfs((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const handleFileChange = (orderId: string, newFiles: File[]) => {
        setFilesByOrder((prevFiles) => ({
            ...prevFiles,
            [orderId]: prevFiles[orderId]
                ? [...prevFiles[orderId], ...newFiles] // Append multiple files
                : [...newFiles], // Create a new array if none exist
        }));
    };

    const removeFile = (orderId: string, fileToRemove: File) => {
        setFilesByOrder((prevFiles) => ({
            ...prevFiles,
            [orderId]: prevFiles[orderId]
                ? prevFiles[orderId].filter((file) => file !== fileToRemove) // Remove the specific file
                : [], // If no files exist for that orderId, return an empty array
        }));
    };

    const [notes, setNotes] = useState("");
    const [checkinDate, setCheckinDate] = useState<Dayjs>(dayjs());

    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleConfirm = async () => {
        setConfirmLoading(true);
        const data = await modifyInboundOrders(
            receivedOrders[0]?.user_id
                ? receivedOrders[0].user_id
                : selectedFoundOrders[0]?.user_id,
            selectedFoundOrders,
            receivedOrders.map((o) => o.check_in_notes).join(","),
            formatDateFromObject(checkinDate),
            shipmentQuantities,
            filesByOrder,
            shelfs,
            receivedOrders.map((o) => o.received_order_id)
        );
        if (data.status === "success") {
            showNotification("Modified inbound order(s)", "", "success");
            setShipmentQuantities({});
            setSelectedFoundOrders([]);
            setOrders([]);
            handleRefetch();
            setOpenCheckInOrderModal(false);
        } else {
            showNotification("Error modifying order(s)", data.message, "error");
        }
        setConfirmLoading(false);
    };

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    return (
        <Dialog
            open={openCheckInOrderModal}
            onClose={() => setOpenCheckInOrderModal(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-[95%] sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenCheckInOrderModal(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Check In Order
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto overflow-x-auto w-full">
                            <div className="flex flex-row items-center justify-between">
                                <span className="font-semibold text-base text-gray-900">
                                    Received Order Details
                                </span>
                                <div className="flex flex-row items-center space-x-4">
                                    {receivedTotalSum !== "" && (
                                        <span className="text-gray-700 text-sm">
                                            Sum: {receivedTotalSum}
                                        </span>
                                    )}
                                    <ColumnOrderDropdown
                                        columnOrder={unitsColumnOrder}
                                        setColumnOrder={setUnitsColumnOrder}
                                        localStorageKey="receivedOrdersColumnOrder"
                                        selectedOrderType={selectedOrderType}
                                        initialColumnOrder={
                                            initialReceivedOrdersColumnOrder
                                        }
                                    />
                                </div>
                            </div>
                            <ReceivedOrdersTable
                                orders={receivedOrders}
                                setOrders={setOrders}
                                results={null}
                                selectedOrders={[]}
                                setSelectedOrders={setOrders}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={() => {}}
                                setTotalSum={setReceivedTotalSum}
                                columnOrder={unitsColumnOrder}
                                hiddenHeaders={["select"]}
                                onRowClick={() => {}}
                                client_type="admin"
                            />

                            <div className="py-4">
                                <div className="h-px w-full bg-gray-200"></div>
                            </div>

                            <div className="pt-2 flex flex-row items-center justify-between">
                                <span className="font-semibold text-base text-gray-900">
                                    Client Inbound Orders
                                </span>
                                <div className="flex flex-row items-center space-x-4">
                                    {totalSum !== "" && (
                                        <span className="text-gray-700 text-sm">
                                            Sum: {totalSum}
                                        </span>
                                    )}
                                    <ColumnOrderDropdown
                                        columnOrder={foundOrdersColumnOrder}
                                        setColumnOrder={
                                            setFoundOrdersColumnOrder
                                        }
                                        localStorageKey={"unitsColumnOrder"}
                                        selectedOrderType={selectedOrderType}
                                        initialColumnOrder={getTableHeaders(
                                            "inbound"
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-wrap mt-2 space-y-2">
                                <input
                                    placeholder="Search"
                                    value={inputValue}
                                    onChange={(e) =>
                                        setInputValue(e.target.value)
                                    }
                                    className="outline-none px-0 py-0 text-xs border border-gray-300 h-8 w-full rounded-md pl-2 focus:border-gray-400 focus:ring-0"
                                    ref={inputRef}
                                    onKeyDown={handleAddKeyword}
                                />
                                {keywords.map((keyword, index) => (
                                    <div
                                        key={index}
                                        className="bg-gray-100 px-2 py-1 rounded-md flex items-center space-x-2 m-1"
                                    >
                                        <span className="text-sm">
                                            {keyword}
                                        </span>
                                        <button
                                            onClick={() =>
                                                handleRemoveKeyword(index)
                                            }
                                            className="text-red-500 text-xs"
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {!loading ? (
                                <OrdersTable
                                    orderStatus="inbound"
                                    orders={foundOrders}
                                    results={null}
                                    selectedOrders={selectedFoundOrders}
                                    setSelectedOrders={setSelectedFoundOrders}
                                    sortLoading={sortLoading}
                                    sortConfig={sortConfig}
                                    toggleSort={toggleSort}
                                    handleRefetch={() => {}}
                                    client_type="admin"
                                    setTotalSum={setTotalSum}
                                    columnOrder={foundOrdersColumnOrder}
                                    hiddenHeaders={[
                                        "input_shipping_quantity",
                                        "remove_selected_row",
                                        "input_shelf_location",
                                        "location",
                                        "qa",
                                    ]}
                                />
                            ) : (
                                <div className="flex flex-row items-center space-x-3 pt-2">
                                    <span className="text-sm text-gray-800">
                                        Searching inbound orders...
                                    </span>
                                    <LoadingWheel />
                                </div>
                            )}

                            <div className="py-4">
                                <div className="h-px w-full bg-gray-200"></div>
                            </div>

                            {selectedFoundOrders.length > 0 && (
                                <>
                                    <div className="pt-4 pb-20">
                                        <span className="font-semibold text-base text-gray-900">
                                            Selected Inbound Orders
                                        </span>
                                        <OrdersTable
                                            orderStatus="inbound"
                                            orders={selectedFoundOrders}
                                            results={null}
                                            selectedOrders={selectedFoundOrders}
                                            setSelectedOrders={
                                                setSelectedFoundOrders
                                            }
                                            sortLoading={sortLoading}
                                            sortConfig={sortConfig}
                                            toggleSort={toggleSort}
                                            handleRefetch={() => {}}
                                            client_type="admin"
                                            setTotalSum={setTotalSum}
                                            columnOrder={foundOrdersColumnOrder}
                                            hiddenHeaders={[
                                                "location",
                                                "qa",
                                                "select",
                                                "input_shelf_location",
                                            ]}
                                            shipmentQuantities={
                                                shipmentQuantities
                                            }
                                            handleQuantityChange={
                                                handleQuantityChange
                                            }
                                            shipmentInputName="Quantity received"
                                            shelfs={shelfs}
                                            handleShelfChange={
                                                handleShelfChange
                                            }
                                            filesByOrder={filesByOrder}
                                            handleFileChange={handleFileChange}
                                            removeFile={removeFile}
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse gap-x-2 space-x-4">
                            <ActionButton
                                label="Check In Orders"
                                handleClick={() => handleConfirm()}
                                height={9}
                                loading={confirmLoading}
                            />
                            <SecondaryButton
                                label="Cancel"
                                handleClick={() =>
                                    setOpenCheckInOrderModal(false)
                                }
                                height={9}
                            />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
