import Cookies from "js-cookie";
import AccountData, { ClientAccountData } from "../../../../models/accountData";
import { ClientTeamPermissions } from "../../../../models/permissions";
import { EmailNotification, NotificationOption } from "../../../../models/adminDashboard";

const baseUrl = process.env.REACT_APP_API_BASE_URL

export const fetchAccountDetails = async () => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account`,
            {
                method: "GET",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                }
            }
        );

        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
};


export const updateAccountDetails = async (
    section: string,
    accountDetails: AccountData | null,
) => {
    try {
        
        var jsonBody = {}
        
        if (section === "personal") {
            jsonBody = {
                section: section,
                first_name: accountDetails?.first_name,
                last_name: accountDetails?.last_name,
                phone_number: accountDetails?.phone_number,
                discord_webhook: accountDetails?.discord_webhook
            }

        } else if (section === "integrations") {
            jsonBody = {
                section: section,
                amazon_store_name: accountDetails?.amazon_store_name,
                inventory_labs_email: accountDetails?.inventory_labs_email,
                inventory_labs_password: accountDetails?.inventory_labs_password,
                boxem_email: accountDetails?.boxem_email,
                boxem_password: accountDetails?.boxem_password,
            }
        }

        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/account`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify(jsonBody)
            }
        );

        const data = await response.json()
        return {success: data.status === "success", message: data.message}
        
    } catch (error) {
        return {success: false, message: String(error)}
    }
}


export const inviteTeamMember = async (invited_email: string, permissions: ClientTeamPermissions) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/v1/team/invite`,
            {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({invited_email, permissions})
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const removeTeamMember = async (team_member_email: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/v1/team/remove/` + team_member_email,
            {
                method: "DELETE",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const editShipmentFrequency = async (frequency: {
    id: number;
    label: string;
    description: string;
    value: string;
}, min_threshold: string) => {
    try {
        const response = await fetch(
            `${baseUrl}/api/v1/account/shipment-frequency`,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({frequency, min_threshold})
            }
        )
        const data = await response.json()
        return data;
    } catch (error) {
        return { data: {status: "failed", message: String(error)} }
    }
}

export const updateNotificationsPreferences = async (notifications: NotificationOption[], discord_webhook: string) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/account/notification-preferences`;
        const response = await fetch(
            url,
            {
                method: "PUT",
                headers: { 
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + Cookies.get("jwt")
                },
                body: JSON.stringify({notifications, discord_webhook})
            }
        );

        const data = await response.json();
        return data
    } catch (error) {
        return {data : { status: "failed", message: String(error)}}
    }
};