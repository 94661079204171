/** @format */

import { useEffect, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { fetchClientData } from "../api/clientsApi";
import Client from "../../../models/clients";
import LoadingWheel from "../../../components/loadingWheel";
import ClientActions from "./client-data/client/clientActions";
import { formatMoney } from "../../../utils/price/formatMoney";
import { shipmentFrequencyOptions } from "../../../models/data";
import DeleteClientModal from "./actions/deleteClientModal";
import { EditClientModal } from "./actions/editClientModal";
import { SmallCopyButton } from "../../../components/copyButton";

interface ClientDrawerProps {
    user_id: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    client?: Client | null;
    setClient?: React.Dispatch<React.SetStateAction<Client | null>>;
}

export default function ClientDrawer({
    user_id,
    open,
    setOpen,
    client,
    setClient,
}: ClientDrawerProps) {
    const [loading, setLoading] = useState(false);

    const [refetchData, setRefetchData] = useState(0);
    const handleRefetch = () => {
        setRefetchData((prev) => 1 + prev);
    };

    const fetchData = async () => {
        const data = await fetchClientData(user_id);
        if (data.status === "success") {
            setClient && setClient(data.data.client_data);
        } else {
        }
        setLoading(false);
    };

    useEffect(() => {
        if (open) {
            if ((user_id && user_id != client?.user_id) || user_id === "") {
                setLoading(true);
                fetchData();
            }
        }
    }, [user_id, refetchData, open]);

    useEffect(() => {
        if (refetchData > 0) {
            setLoading(true);
            fetchData();
        }
    }, [refetchData]);

    const [openEditClientModal, setOpenEditClientModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const selectedFrequency = shipmentFrequencyOptions.find(
        (option) => option.value === client?.fba_shipment_frequency
    );

    const clientInfo = [
        {
            label: "Full name",
            values: [`${client?.first_name} ${client?.last_name}`],
        },
        {
            label: "Phone number",
            values: [client?.phone_number],
        },
        { label: "Email", values: [client?.email] },
        {
            label: "Amazon store name",
            values: [client?.amazon_store_name || "Not provided"],
        },
        {
            label: "Inventory Labs Credentials",
            values: [
                client?.inventory_labs_email || "Not provided",
                client?.inventory_labs_password,
            ],
        },
        {
            label: "Boxem Credentials",
            values: [
                client?.boxem_email || "Not provided",
                client?.boxem_password,
            ],
        },
        {
            label: "Tax rate",
            values: [`${client?.tax_rate}%` || "Not provided"],
        },
        {
            label: "FBA Shipment Frequency",
            values: [selectedFrequency?.label, client?.min_unit_threshold],
        },
        {
            label: "Amazon fulfilled unit price",
            values: [
                `${
                    client?.amazon_unit_price
                        ? formatMoney(client?.amazon_unit_price)
                        : "None"
                }`,
            ],
        },
        {
            label: "Merchant fulfilled unit price",
            values: [
                `${
                    client?.merchant_unit_price
                        ? formatMoney(client?.merchant_unit_price)
                        : "None"
                }`,
            ],
        },
        {
            label: "Reshipping fulfilled unit price",
            values: [
                `${
                    client?.reshipping_unit_price
                        ? formatMoney(client?.reshipping_unit_price)
                        : "None"
                }`,
            ],
        },
        {
            label: "Max shipping names",
            values: [client?.max_shipping_names],
        },
        {
            label: "Shipping names",
            values: client?.shipping_names ? client?.shipping_names : [],
        },
        {
            label: "Referral code",
            values: [
                client?.referral_code,
                client?.referrer_full_name ? client?.referrer_full_name : "",
            ],
        },
    ];

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                        <DialogPanel
                            transition
                            className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                        >
                            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                <div className="px-4 py-6 sm:px-6">
                                    <div className="flex items-start justify-between">
                                        <h2
                                            id="slide-over-heading"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Client Information
                                        </h2>
                                        <div className="ml-3 flex h-7 items-center">
                                            <button
                                                type="button"
                                                onClick={() => setOpen(false)}
                                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-accent"
                                            >
                                                <span className="absolute -inset-2.5" />
                                                <span className="sr-only">
                                                    Close panel
                                                </span>
                                                <XMarkIcon
                                                    aria-hidden="true"
                                                    className="h-6 w-6"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Main */}
                                <div className="sm:px-6 px-4">
                                    {!loading ? (
                                        <div className="pt-10">
                                            <h3 className="font-bold text-xl leading-7 text-gray-900">
                                                {client?.first_name +
                                                    " " +
                                                    client?.last_name}
                                            </h3>
                                            <h5 className="">
                                                {client?.email}
                                            </h5>
                                            {client && (
                                                <ClientActions
                                                    client={client}
                                                    setOpenEditClientModal={
                                                        setOpenEditClientModal
                                                    }
                                                    setOpenDeleteModal={
                                                        setOpenDeleteModal
                                                    }
                                                    handleClientRefetch={
                                                        handleRefetch
                                                    }
                                                />
                                            )}
                                            <div className="mt-6">
                                                <dl className="grid grid-cols-1 sm:grid-cols-2">
                                                    {clientInfo.map((info) => (
                                                        <div
                                                            key={info.label}
                                                            className="border-t border-gray-100 backdrop: py-6 sm:col-span-1 sm:px-0"
                                                        >
                                                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                                                {info.label}
                                                            </dt>
                                                            {info.values &&
                                                                info.values.map(
                                                                    (value) => (
                                                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                                                            {
                                                                                value
                                                                            }
                                                                            {(info.label ===
                                                                                "Inventory Labs Credentials" ||
                                                                                info.label ===
                                                                                    "Boxem Credentials") &&
                                                                                value !==
                                                                                    "Not provided" &&
                                                                                value !==
                                                                                    "" && (
                                                                                    <SmallCopyButton
                                                                                        textToCopy={
                                                                                            String(
                                                                                                value
                                                                                            ) ||
                                                                                            ""
                                                                                        }
                                                                                    />
                                                                                )}
                                                                        </dd>
                                                                    )
                                                                )}
                                                        </div>
                                                    ))}
                                                </dl>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-row items-center justify-center pt-44">
                                            <LoadingWheel />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DialogPanel>
                    </div>
                </div>

                <DeleteClientModal
                    openDeleteModal={openDeleteModal}
                    setOpenDeleteModal={setOpenDeleteModal}
                    selectedClients={[client?.user_id ?? ""]}
                    mode="single"
                    allClientsSelected={false}
                    handleRefetch={handleRefetch}
                />

                {client && (
                    <EditClientModal
                        client={client}
                        openEditClientModal={openEditClientModal}
                        setOpenEditClientModal={setOpenEditClientModal}
                        handleRefetch={handleRefetch}
                    />
                )}
            </div>
        </Dialog>
    );
}
