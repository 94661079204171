/** @format */

import { useState, useEffect, useRef } from "react";
import ClientSideBar from "../../../components/client/sideBar";
import Header from "../../../components/header";
import DashboardCards from "./dashboardCards";
import OverviewCards from "./overviewCards";
import DashboardCardsModel from "../../../models/dashboardCards";
import { fetchDashboardData } from "./api/dashboardApi";
import LoadingWheel from "../../../components/loadingWheel";
import DashboardStats from "../../../models/dashboardStats";
import {
    AdminNotification,
    WarehouseAddress,
} from "../../../models/adminDashboard";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import { formatMoney } from "../../../utils/price/formatMoney";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
import BarChart from "./barChart";

function ReferralsBanner({
    referralCreditAmount,
    setRemovedBanner,
}: {
    referralCreditAmount: number;
    setRemovedBanner: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const navigate = useNavigate();

    return (
        <div className="pb-4">
            <div
                onClick={() => navigate("/referrals")}
                className="rounded-md bg-accentbg px-4 py-3 hover:bg-accenthighlight hover:text-white text-accent cursor-pointer transition-all"
            >
                <div className="flex">
                    <div className="flex-shrink-0">
                        <InformationCircleIcon
                            aria-hidden="true"
                            className="h-5 w-5 font-medium"
                        />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm font-medium">
                            Get {formatMoney(referralCreditAmount)} for
                            referring a friend.
                        </p>
                        <div className="flex flex-row items-center space-x-6">
                            <p className="mt-3 text-sm md:ml-6 md:mt-0">
                                <button
                                    onClick={() => navigate("/referrals")}
                                    className="whitespace-nowrap font-medium hover:text-accenthighlight"
                                >
                                    Details
                                    <span aria-hidden="true"> &rarr;</span>
                                </button>
                            </p>
                            <XMarkIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    Cookies.set(
                                        "client_hidden_referral_tag",
                                        "true"
                                    );
                                    setRemovedBanner(true);
                                }}
                                className="w-5 h-5 hover:text-accenthighlight cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface GraphData {
    title: string;
    labels: string[];
    values: number[];
}

export default function DashboardPage() {
    const [searchParams] = useSearchParams();
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };
    const [refetchData, setRefetchData] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const [dashboardCardsData, setDashboardCardsData] =
        useState<DashboardCardsModel | null>(null);

    const [dashboardOverviewStats, setDashboardOverviewStats] =
        useState<DashboardStats | null>(null);

    const [warehouseAddress, setWarehouseAddress] =
        useState<WarehouseAddress | null>(null);

    const [notifications, setNotifications] = useState<AdminNotification[]>([]);

    const [referralCreditAmount, setReferralCreditAmount] = useState(0);

    const [graphData, setGraphData] = useState<GraphData[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data = await fetchDashboardData();
            if (data.status === "success") {
                setDashboardCardsData(data.data.orders);
                setDashboardOverviewStats(data.data.spenditure);
                setWarehouseAddress(data.data.address);
                setNotifications(data.data.notifications);
                setReferralCreditAmount(data.data.referral_credit_amount);
                setGraphData(data.data.graph_data);
            }
            setLoading(false);
        };
        fetchData();
    }, [refetchData]);

    const [removedBanner, setRemovedBanner] = useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col w-full">
                <div className="sm:px-10 px-4">
                    <Header />
                </div>
                <div className="sm:mx-10 mx-4 pt-10 flex flex-col h-full">
                    {Cookies.get("client_hidden_referral_tag") !== "true" &&
                        !removedBanner && (
                            <ReferralsBanner
                                referralCreditAmount={referralCreditAmount}
                                setRemovedBanner={setRemovedBanner}
                            />
                        )}
                    {/* <div className="flex flex-row items-center justify-between">
                        <span className="text-2xl font-bold">Dashboard</span>
                        <div className="flex text-sm flex-row items-center text-[#7785cc] space-x-1 font-medium">
                            <FaDiscord className="h-4 w-4 text-[#7785cc]" />
                            <a
                                href="https://discord.gg/eBV2m668AE"
                                target="_blank"
                            >
                                Join Discord Server
                            </a>
                        </div>
                    </div> */}

                    {!loading ? (
                        <div>
                            {/* <DashboardCards
                                dashboardCardsData={dashboardCardsData}
                            /> */}
                            {/* <div className="pt-4"></div> */}

                            <OverviewCards
                                dashboardOverviewStats={dashboardOverviewStats}
                                warehouseAddress={warehouseAddress}
                                notificationsData={notifications}
                                setNotificationsData={setNotifications}
                                handleRefetch={handleRefetch}
                            />

                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-10 pt-6 pb-4 gap-y-6">
                                {graphData &&
                                    graphData.map((graph) => (
                                        <div className="space-y-2">
                                            <h2 className="font-semibold text-sm">
                                                {graph.title}
                                            </h2>
                                            <BarChart
                                                labels={graph.labels}
                                                data_list={graph.values}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-grow items-center pt-44 justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
