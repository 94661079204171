/** @format */

"use client";

import { useEffect, useState } from "react";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

interface SmallSelectorProps {
    selected: {
        id: number;
        name: string;
        value: string;
    };
    setSelected: React.Dispatch<
        React.SetStateAction<{
            id: number;
            name: string;
            value: string;
        }>
    >;
    selectorData: {
        id: number;
        name: string;
        value: string;
    }[];
}

export default function SmallSelector({
    selected,
    setSelected,
    selectorData,
}: SmallSelectorProps) {
    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
                <ListboxButton className="relative w-full rounded-md hover:bg-gray-50 cursor-pointer bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs">
                        {selected.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-30 mt-1 max-h-60 w-44 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {selectorData.map((type) => (
                        <ListboxOption
                            key={type.id}
                            value={type}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                        >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-sm">
                                {type.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}

interface SmallSelectorWithNavigate {
    selectorData: {
        id: number;
        name: string;
        value: string;
    }[];
}

export function SmallSelectorWithATag({
    selectorData,
}: SmallSelectorWithNavigate) {
    const navigate = useNavigate();

    const getMatchedPath = () => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        const matchedItem = selectorData.find(
            (item) => item.value === normalizedPath
        );
        return matchedItem ? matchedItem : null;
    };

    const [selected, setSelected] = useState<{
        id: number;
        name: string;
        value: string;
    }>(getMatchedPath() || { id: 1, name: "Units", value: "/inbound/units" });

    useEffect(() => {
        const matchedItem = getMatchedPath();

        if (matchedItem) {
            setSelected(matchedItem);
        }
    }, [window.location.pathname, selectorData]);

    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
                <ListboxButton className="relative w-full rounded-md hover:bg-gray-50 cursor-pointer bg-white h-8 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs">
                        {selected.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-30 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {selectorData.map((type) => (
                        <a
                            key={type.id}
                            href={type.value}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                        >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-sm">
                                {type.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </span>
                        </a>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}

interface SmallSelectorWithNavigate {
    selectorData: {
        id: number;
        name: string;
        value: string;
    }[];
}

export function SmallSelectorWithNavigate({
    selectorData,
}: SmallSelectorWithNavigate) {
    const navigate = useNavigate();

    const getMatchedPath = () => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        const matchedItem = selectorData.find(
            (item) => item.value === normalizedPath
        );
        return matchedItem ? matchedItem : null;
    };

    const [selected, setSelected] = useState<{
        id: number;
        name: string;
        value: string;
    }>(getMatchedPath() || { id: 1, name: "Units", value: "/inbound/units" });

    useEffect(() => {
        const matchedItem = getMatchedPath();

        if (matchedItem) {
            setSelected(matchedItem);
        }
    }, [window.location.pathname, selectorData]);

    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative">
                <ListboxButton className="relative w-full rounded-md hover:bg-gray-50 cursor-pointer bg-white h-8 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-accent sm:text-sm sm:leading-6">
                    <span className="block truncate text-xs">
                        {selected.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    transition
                    className="absolute z-30 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {selectorData.map((type) => (
                        <ListboxOption
                            key={type.id}
                            value={type}
                            onClick={() => navigate(type.value)}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-accent data-[focus]:text-white"
                        >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-sm">
                                {type.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-accent group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                />
                            </span>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    );
}
