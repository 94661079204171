/** @format */

import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { InputField } from "../../../../../../components/inputField";
import LoadingWheel from "../../../../../../components/loadingWheel";
import { modifyInboundOrders } from "../../../../api/unitsApi";
import { useNotification } from "../../../../../../utils/notification/notificationContext";
import dayjs, { Dayjs } from "dayjs";
import SingleCalendar from "../../../../../../components/singleCalendar";
import { formatDateFromObject } from "../../../../../../utils/date/date_formatter";
import {
    Order,
    ShelfLocations,
    ShipmentQuantities,
} from "../../../../../../models/order";
import { useParams, useSearchParams } from "react-router-dom";
import { useClient } from "../../../../../../utils/client/clientContext";
import OrdersTable, {
    getTableHeaders,
} from "../../../../../../tables/ordersTable";
import { useSort } from "../../../../../../hooks/useSort";
import { orderTypes } from "../../../../../../models/data";
import { Column } from "../../../../../../tables/models";
import ColumnOrderDropdown from "../../../../../../tables/columnDragPopup";

export default function ModifyInboundOrderModal({
    selectedOrders,
    setSelectedOrders,
    openModifyInboundOrder,
    setOpenModifyInboundOrder,
    handleFullRefetch,
}: {
    selectedOrders: Order[];
    setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>;
    openModifyInboundOrder: boolean;
    setOpenModifyInboundOrder: React.Dispatch<React.SetStateAction<boolean>>;
    handleFullRefetch: () => void;
}) {
    const { client, user_id, handleOrderCountRefetch } = useClient();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [selectedOrderType, setSelectedOrderType] = useState(orderTypes[0]);

    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const [notes, setNotes] = useState("");
    const [checkinDate, setCheckinDate] = useState<Dayjs>(dayjs());
    const [filesByOrder, setFilesByOrder] = useState<{
        [orderId: string]: File[];
    }>({});

    const [shipmentQuantities, setShipmentQuantities] =
        useState<ShipmentQuantities>({});

    const [shelfs, setShelfs] = useState<ShelfLocations>({});

    const handleQuantityChange = (orderId: string, value: string) => {
        setShipmentQuantities((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const handleShelfChange = (orderId: string, value: string) => {
        setShelfs((prevQuantities) => ({
            ...prevQuantities,
            [orderId]: value,
        }));
    };

    const handleFileChange = (orderId: string, newFiles: File[]) => {
        setFilesByOrder((prevFiles) => ({
            ...prevFiles,
            [orderId]: prevFiles[orderId]
                ? [...prevFiles[orderId], ...newFiles] // Append multiple files
                : [...newFiles], // Create a new array if none exist
        }));
    };

    const removeFile = (orderId: string, fileToRemove: File) => {
        setFilesByOrder((prevFiles) => ({
            ...prevFiles,
            [orderId]: prevFiles[orderId]
                ? prevFiles[orderId].filter((file) => file !== fileToRemove) // Remove the specific file
                : [], // If no files exist for that orderId, return an empty array
        }));
    };

    const handleModifyOrder = async () => {
        setLoading(true);
        const data = await modifyInboundOrders(
            user_id || "",
            selectedOrders,
            notes,
            formatDateFromObject(checkinDate),
            shipmentQuantities,
            filesByOrder,
            shelfs
        );
        if (data.status === "success") {
            showNotification("Modified inbound order(s)", "", "success");
            setOpenModifyInboundOrder(false);
            setShipmentQuantities({});
            setSelectedOrders([]);
            setShelfs({});
            setNotes("");
            setCheckinDate(dayjs());
            handleFullRefetch();
            handleOrderCountRefetch();
        } else {
            showNotification("Error modifying order(s)", data.message, "error");
        }
        setLoading(false);
    };

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        getTableHeaders("inbound")
    );
    const hiddenHeaders: string[] = ["select", "location", "quick_actions"];

    return (
        <Dialog
            open={openModifyInboundOrder}
            onClose={() => setOpenModifyInboundOrder(false)}
            className="relative z-20"
        >
            <DialogBackdrop
                transition
                className="overflow-y-visible fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-20 w-screen">
                <div className="h-[90%] flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="h-[90%] w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-[95%] sm:p-6 flex flex-col data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                onClick={() => setOpenModifyInboundOrder(false)}
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-accent focus:ring-offset-2"
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon
                                    aria-hidden="true"
                                    className="h-6 w-6"
                                />
                            </button>
                        </div>

                        <DialogTitle
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900 pb-5"
                        >
                            Modify shipment status
                            <div className="text-sm text-gray-600 font-normal flex flex-row items-center divide-x space-x-2 pt-1">
                                <span>
                                    {client?.first_name +
                                        " " +
                                        client?.last_name}
                                </span>
                                <span className="pl-2">{user_id}</span>
                            </div>
                        </DialogTitle>
                        <div className="w-full h-px bg-gray-200"></div>

                        <div className="pt-4"></div>

                        <div className="flex-1 mb-4 overflow-y-auto overflow-x-auto w-full">
                            <div className="flex flex-col w-full">
                                <div className="flex flex-col sm:flex-row w-full space-y-3 sm:space-y-0 sm:space-x-4 sm:items-end">
                                    <div className="w-96">
                                        <SingleCalendar
                                            selectedDate={checkinDate}
                                            setSelectedDate={setCheckinDate}
                                            label="Check in date"
                                        />
                                    </div>
                                    <div className="w-full pt-0.5">
                                        <InputField
                                            label="Notes"
                                            value={notes}
                                            onChange={(e) =>
                                                setNotes(e.target.value)
                                            }
                                            placeholder=""
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="text-sm pt-5 flex flex-row items-center justify-between">
                                <span>
                                    {selectedOrders.length} order selected
                                </span>
                                <div className="flex flex-row items-center space-x-4">
                                    {totalSum !== "" && (
                                        <span className="text-gray-700 text-sm">
                                            Sum: {totalSum}
                                        </span>
                                    )}
                                    <ColumnOrderDropdown
                                        columnOrder={unitsColumnOrder}
                                        setColumnOrder={setUnitsColumnOrder}
                                        localStorageKey={"unitsColumnOrder"}
                                        selectedOrderType={selectedOrderType}
                                        initialColumnOrder={getTableHeaders(
                                            "inventory"
                                        )}
                                    />
                                </div>
                            </div>

                            <OrdersTable
                                orderStatus="inbound"
                                orders={selectedOrders}
                                results={null}
                                selectedOrders={[]}
                                setSelectedOrders={setSelectedOrders}
                                sortLoading={sortLoading}
                                sortConfig={sortConfig}
                                toggleSort={toggleSort}
                                handleRefetch={() => {}}
                                client_type="admin"
                                setTotalSum={setTotalSum}
                                columnOrder={unitsColumnOrder}
                                hiddenHeaders={hiddenHeaders}
                                shipmentQuantities={shipmentQuantities}
                                handleQuantityChange={handleQuantityChange}
                                shipmentInputName="Quantity received"
                                shelfs={shelfs}
                                handleShelfChange={handleShelfChange}
                                filesByOrder={filesByOrder}
                                handleFileChange={handleFileChange}
                                removeFile={removeFile}
                            />
                        </div>

                        {/* Fixed buttons at the bottom */}
                        <div className="pt-4 sm:pt-0 sm:flex sm:flex-row-reverse">
                            <button
                                onClick={() => handleModifyOrder()}
                                type="button"
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight sm:ml-3 sm:w-auto items-center"
                            >
                                {loading ? (
                                    <span className="pr-2 items-center flex flex-row justify-center">
                                        <LoadingWheel
                                            size="small"
                                            color="white"
                                        />
                                    </span>
                                ) : (
                                    <></>
                                )}
                                Confirm changes
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenModifyInboundOrder(false)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                Cancel
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
