/** @format */

"use client";

import { useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

interface ConfirmLoggedOutModalProps {
    openConfirmLoggedOutModal: boolean;
    setOpenConfirmLoggedOutModal: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenCreateOutbountShipment: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export default function ConfirmLoggedOutModal({
    openConfirmLoggedOutModal,
    setOpenConfirmLoggedOutModal,
    setOpenCreateOutbountShipment,
}: ConfirmLoggedOutModalProps) {
    return (
        <Dialog
            open={openConfirmLoggedOutModal}
            onClose={setOpenConfirmLoggedOutModal}
            className="relative z-50"
        >
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                                <CheckIcon
                                    aria-hidden="true"
                                    className="size-6 text-green-600"
                                />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <DialogTitle
                                    as="h3"
                                    className="text-base font-semibold text-gray-900"
                                >
                                    Confirm Account Log Out
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Please don't forget to log out of the
                                        client's inventory labs / boxem account.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button
                                type="button"
                                onClick={() => {
                                    setOpenConfirmLoggedOutModal(false);
                                    setOpenCreateOutbountShipment(false);
                                }}
                                className="inline-flex w-full justify-center rounded-md bg-accent px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-accenthighlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent"
                            >
                                I've logged out
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
