/** @format */

import { useSearchParams } from "react-router-dom";
import { useNotification } from "../../../../utils/notification/notificationContext";
import { useSort } from "../../../../hooks/useSort";
import { useEffect, useState } from "react";
import { fetchShipments } from "../api/outboundApi";
import ClientSideBar from "../../../../components/client/sideBar";
import Header from "../../../../components/header";
import ShipmentsTable from "../../../../tables/shipmentsTable";
import Results from "../../../../models/results";
import Shipment from "../../../../models/shipments";
import ClientSearchBar from "../../../../components/client/searchBar";
import ColumnOrderDropdown from "../../../../tables/columnDragPopup";
import { shipmentTypes } from "../../../../models/data";
import { initialShipmentsColumnOrder } from "../../../../tables/shipmentsTable";
import { Column } from "../../../../tables/models";
import LoadingWheel from "../../../../components/loadingWheel";
import { useClient } from "../../../../utils/client/clientContext";
import { SecondaryButton } from "../../../../components/buttons";
import { ArrowUpTrayIcon } from "@heroicons/react/20/solid";
import { exportData } from "../../api/exportApi";
import { SmallSelectorWithNavigate } from "../../../../components/dropdowns/smallSelector";
import { ClientOrderCount } from "../../../../models/clients";
import {
    ClipboardDocumentCheckIcon,
    InboxIcon,
} from "@heroicons/react/24/outline";
import TabSelector from "../../../../components/tabSelector";

export const pendingTabs = (
    orderCount: ClientOrderCount | null | undefined // Pass the count as an argument
) => [
    {
        name: `Requested Shipments ${
            orderCount
                ? `(${orderCount.requested_outbound.toLocaleString()})`
                : ""
        }`,
        href: "/outbound/requested",
        icon: InboxIcon,
        value: "check-in",
    },
    {
        name: `Requested Details ${
            orderCount
                ? `(${orderCount.shipping_details.toLocaleString()})`
                : ""
        }`,
        href: "/outbound/requested-details",
        icon: ClipboardDocumentCheckIcon,
        value: "received",
    },
];

function OutboundRequestedPage() {
    const { showNotification } = useNotification();
    const [searchParams] = useSearchParams();
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const { sortConfig, toggleSort, sortLoading, setSortLoading } = useSort({
        key: "created_at",
        order: "desc",
    });

    const [shipments, setShipments] = useState<Shipment[]>([]);

    const [results, setResults] = useState<Results>({
        showing_from: 0,
        showing_to: 0,
        total_results: 0,
        current_page: 0,
    });

    const [loading, setLoading] = useState(true);

    const [refetchData, setRefetchData] = useState<number>(0);
    const handleRefetch = () => {
        setRefetchData((prevValue) => prevValue + 1);
    };

    const fetchData = async () => {
        const data = await fetchShipments(
            searchParams,
            sortConfig,
            "requested"
        );
        if (data.status === "success") {
            setShipments(data.data.shipments);
            setResults(data.data.results);
        } else {
            showNotification("Failed fetching data", data.message, "error");
        }
        setLoading(false);
        setSortLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [refetchData]);

    useEffect(() => {
        fetchData();
    }, [sortConfig]);

    const [selectedShipments, setSelectedShipments] = useState<Shipment[]>([]);

    const [totalSum, setTotalSum] = useState("");

    const [unitsColumnOrder, setUnitsColumnOrder] = useState<Column[]>(
        initialShipmentsColumnOrder
    );

    const [selectedOrderType, setSelectedOrderType] = useState(
        shipmentTypes[1]
    );
    const [exportLoading, setExportLoading] = useState(false);

    return (
        <div className="flex flex-row">
            <ClientSideBar />
            <div className="flex flex-col flex-grow overflow-x-auto sm:px-10 px-4">
                <div className="">
                    <Header />
                </div>

                <div className="pt-10 flex flex-col overflow-x-auto min-h-screen">
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center">
                            <span className="text-2xl font-bold">Outbound</span>
                            <span className="ml-4 w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                                {orderCount?.requested_outbound}
                            </span>
                        </div>
                        {totalSum !== "" && (
                            <div className="text-gray-700 text-sm">
                                Total Sum: {totalSum}
                            </div>
                        )}
                    </div>

                    <TabSelector tabs={pendingTabs(orderCount)} />

                    <div className="flex flex-row items-start space-x-4 pt-4">
                        <SmallSelectorWithNavigate
                            selectorData={[
                                {
                                    id: 1,
                                    name: "Shipped",
                                    value: "/outbound/shipped",
                                },
                                {
                                    id: 2,
                                    name: "Requested",
                                    value: "/outbound/requested",
                                },
                                {
                                    id: 4,
                                    name: "Trackings",
                                    value: "/outbound/trackings",
                                },
                            ]}
                        />
                        <ClientSearchBar
                            setData={setShipments}
                            setLoading={setLoading}
                            handleRefetch={handleRefetch}
                            page="shipments"
                            sub_page="requested"
                        />
                        <ColumnOrderDropdown
                            columnOrder={unitsColumnOrder}
                            setColumnOrder={setUnitsColumnOrder}
                            localStorageKey="shipmentsColumnOrder"
                            selectedOrderType={selectedOrderType}
                            initialColumnOrder={initialShipmentsColumnOrder}
                            buttonHeightClass="h-8"
                        />
                        <SecondaryButton
                            label="Export"
                            iconDisplayed={ArrowUpTrayIcon}
                            handleClick={() =>
                                exportData(
                                    "outbound",
                                    "requested",
                                    setExportLoading,
                                    showNotification
                                )
                            }
                            loading={exportLoading}
                            height={8}
                        />
                    </div>

                    {!loading ? (
                        <ShipmentsTable
                            shipmentType="requested"
                            shipments={shipments}
                            setShipments={setShipments}
                            results={results}
                            selectedShipments={selectedShipments}
                            setSelectedShipments={setSelectedShipments}
                            sortLoading={sortLoading}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                            handleRefetch={handleRefetch}
                            client_type="client"
                            setTotalSum={setTotalSum}
                            columnOrder={unitsColumnOrder}
                            hiddenHeaders={["date_shipped"]}
                        />
                    ) : (
                        <div className="flex flex-grow items-center justify-center">
                            <LoadingWheel />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OutboundRequestedPage;
