/** @format */

import {
    CheckCircleIcon,
    XMarkIcon,
    CheckIcon,
} from "@heroicons/react/24/solid";
import { motion } from "framer-motion";

export default function FeaturesTable() {
    const features = [
        {
            name: "Guarantees Your Inventory Meets Amazon's Requirements",
            urva: true,
            others: true,
        },
        {
            name: "24/7 Support",
            urva: true,
            others: true,
        },
        { name: "24-Hour Turnaround", urva: true, others: false },
        { name: "Dedicated Dashboard", urva: true, others: false },
        { name: "Transparent Pricing", urva: true, others: false },
        { name: "100% Sales-Tax Free", urva: true, others: false },
    ];

    const rowVariants = {
        hidden: { opacity: 0, y: 20 }, // Start slightly below
        visible: { opacity: 1, y: 0 }, // Fade in and slide up
    };

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse border border-gray-300 text-left">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-4 text-sm font-semibold text-gray-900">
                            Features
                        </th>
                        <th className="px-6 py-4 w-64 text-sm text-center font-semibold text-gray-900 border-l border-gray-300">
                            URVA
                        </th>
                        <th className="px-2 py-4 w-64 text-sm text-center font-semibold text-gray-900 border-l border-gray-300">
                            <div className="hidden md:block">
                                Other Prep Centers
                            </div>
                            <div className="block md:hidden">
                                Other <br />
                                Prep Centers
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {features.map((feature, index) => (
                        <motion.tr
                            key={feature.name}
                            className={
                                index % 2 === 0
                                    ? "border-t border-gray-200 bg-gray-50"
                                    : "border-t border-gray-200"
                            }
                            initial="hidden" // Initial state
                            whileInView="visible" // Animate when in view
                            viewport={{ once: true }} // Trigger only once
                            variants={rowVariants}
                            transition={{
                                duration: 0.6,
                                delay: index * 0.1, // Stagger animations
                            }}
                        >
                            <th className="sm:px-6 px-2 text-center sm:text-start py-4 sm:text-sm text-3xs font-medium text-gray-900">
                                {feature.name}
                            </th>
                            <td className="px-6 py-4 text-center border-l border-gray-300">
                                {feature.urva ? (
                                    <CheckIcon className="mx-auto h-5 w-5 text-green-500" />
                                ) : (
                                    <XMarkIcon className="mx-auto h-5 w-5 text-red-500" />
                                )}
                            </td>
                            <td className="px-6 py-4 text-center border-l border-gray-300">
                                {feature.others ? (
                                    <CheckIcon className="mx-auto h-5 w-5 text-green-500" />
                                ) : (
                                    <XMarkIcon className="mx-auto h-5 w-5 text-red-500" />
                                )}
                            </td>
                        </motion.tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
