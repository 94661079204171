/** @format */

import { SortConfig } from "../../../../hooks/useSort";
import { apiRequest } from "../../../../utils/api/apiRequest";
import { buildUrl } from "../../../../utils/url/buildUrl";

export const fetchContacts = async (searchParams: URLSearchParams, sortConfig: SortConfig) => {
    const baseUrl = `/admin/leads/contacts`;
    const params = {
        offset: searchParams.get('offset'),
        limit: searchParams.get('limit'),
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc"
    };

    const url = buildUrl(baseUrl, params);
    
    return apiRequest(url, "GET")
}

export const createContact = async (
    email: string,
    name: string,
    notes: string,
    phone: string
) => {
    return apiRequest("/admin/leads/contacts", "POST", { email, name, notes, phone });
};

export const deleteContact = async (contact_id: string) => {
    return apiRequest("/admin/leads/contacts", "DELETE", {contact_id})
}

export const fetchFormSubmissions = async (searchParams: URLSearchParams, sortConfig: SortConfig) => {
    const baseUrl = `/admin/leads/form-submissions`;
    const params = {
        offset: searchParams.get('offset'),
        limit: searchParams.get('limit'),
        sort_key: sortConfig ? sortConfig.key : "created_at",
        sort_order: sortConfig ? sortConfig.order : "desc"
    };

    const url = buildUrl(baseUrl, params);
    
    return apiRequest(url, "GET")
}

export const updateFormSubmissions = async (form_submission_ids: string[], status: string) => {
    return apiRequest('/admin/leads/form-submissions', "PUT", {form_submission_ids, status})
}

export const deleteFormSubmission = async (form_submission_id: string) => {
    return apiRequest('/admin/leads/form-submissions', "DELETE", {form_submission_id})
}

export const editNotesFormSubmission = async (form_submission_id: string, notes: string) => {
    return apiRequest('/admin/leads/form-submissions/notes', "PUT", {form_submission_id, notes})
}