/** @format */

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { PiShippingContainer } from "react-icons/pi";
import { TbBus } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineCreditCard } from "react-icons/hi2";
import Settings from "../settings";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconType } from "react-icons";
import { useClient } from "../../utils/client/clientContext";
import { ClientOrderCount } from "../../models/clients";
import { VscReferences } from "react-icons/vsc";
import { LuPackageSearch } from "react-icons/lu";
import Joyride from "react-joyride";
import Cookies from "js-cookie";

type OpenItems = {
    [key: string]: boolean;
};

export const clientNavigationPages = (
    orderCount: ClientOrderCount | null | undefined // Pass the count as an argument
) => [
    {
        name: "Dashboard",
        href: "/",
        icon: LuLayoutDashboard,
        current: true,
        customClassName: "dashboard-button",
    },
    {
        name: "Inbound",
        icon: TbBus,
        current: false,
        children: [
            {
                name: "Units",
                href: "/inbound/units",
                count: orderCount?.inbound || "",
            },
            {
                name: "Trackings",
                href: "/inbound/trackings",
                count: orderCount?.inbound_trackings || "",
            },
            {
                name: "Shipping Names",
                href: "/inbound/shipping-names",
                count: "",
            },
        ],
        href: "/inbound/units",
        customClassName: "inbound-button",
    },
    {
        name: "Received",
        icon: LuPackageSearch,
        current: false,
        children: [
            {
                name: "Needs Check In",
                href: "/received/needs-check-in",
                count: orderCount?.received || "",
            },
            {
                name: "Checked In",
                href: "/received/checked-in",
                count: orderCount?.checked_in || "",
            },
        ],
        href: "/received/needs-check-in",
        customClassName: "received-button",
    },
    {
        name: "Inventory",
        icon: MdOutlineInventory2,
        current: false,
        children: [
            {
                name: "Units",
                href: "/inventory/units",
                count: orderCount?.inventory || "",
            },
        ],
        href: "/inventory/units",
        customClassName: "inventory-button",
    },
    {
        name: "Outbound",
        icon: PiShippingContainer,
        current: false,
        children: [
            {
                name: "Shipments",
                href: "/outbound/shipped",
                count: orderCount?.outbound || "",
            },
            {
                name: "Requested",
                href: "/outbound/requested",
                count: orderCount
                    ? orderCount.requested_outbound +
                      orderCount.shipping_details
                    : "",
            },
            {
                name: "Trackings",
                href: "/outbound/trackings",
                count: orderCount?.outbound_trackings || "",
            },
        ],
        href: "/outbound/shipped",
        customClassName: "outbound-button",
    },
    {
        name: "Billing",
        href: "/billing",
        icon: HiOutlineCreditCard,
        current: false,
        customClassName: "billing-button",
    },
    {
        name: "Referrals",
        href: "/referrals",
        icon: VscReferences,
        current: false,
        customClassName: "referrals-button",
    },
];

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

const SidebarIconWithPopup = ({
    item,
    currentPath,
}: {
    item: {
        name: string;
        href: string;
        icon: IconType;
        current: boolean;
        children?: { name: string; href: string }[];
    };
    currentPath: string;
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    return (
        <div
            className="relative flex items-center flex-col py-1.5 rounded-md hover:bg-gray-100"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <item.icon
                className="h-6 w-6 shrink-0 text-gray-600"
                onClick={() => navigate(item.href)}
            />
            <div
                className={`
            absolute left-full ml-0 top-0 bg-white text-gray-700 shadow-lg rounded-lg p-2 w-48
            transition-all duration-300 ease-in-out transform
            ${
                isHovered
                    ? "opacity-100 translate-x-0"
                    : "opacity-0 translate-x-2 pointer-events-none"
            }
          `}
            >
                <div className="flex flex-col space-y-1">
                    {item.children &&
                        item.children.map((subItem) => (
                            <a
                                href={subItem.href}
                                className={`hover:bg-gray-100 p-2 rounded-md cursor-pointer text-sm ${
                                    currentPath === subItem.href
                                        ? "bg-gray-100"
                                        : ""
                                }`}
                            >
                                {subItem.name}
                            </a>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default function ClientSideBar() {
    const [searchParams] = useSearchParams();
    const { client, setClient, clientLoading, user_id, setUserId, orderCount } =
        useClient();

    const navigate = useNavigate();
    const [currentPath, setCurrentPath] = useState("");
    const [isMinimized, setIsMinimized] = useState(() => {
        // Initialize state from localStorage, default to false if not set
        const saved = localStorage.getItem("sidebarMinimized");
        return saved !== null ? JSON.parse(saved) : false;
    });

    const [openItems, setOpenItems] = useState(() => {
        const saved = localStorage.getItem("sidebarOpenItems");
        return saved !== null ? JSON.parse(saved) : {};
    });

    useEffect(() => {
        const normalizedPath = window.location.pathname.replace(/\/$/, "");
        setCurrentPath(normalizedPath);
    }, []);

    useEffect(() => {
        localStorage.setItem("sidebarMinimized", JSON.stringify(isMinimized));
    }, [isMinimized]);

    useEffect(() => {
        // Save open items state to localStorage
        localStorage.setItem("sidebarOpenItems", JSON.stringify(openItems));
    }, [openItems]);

    const toggleSidebar = () => {
        setIsMinimized(!isMinimized);
    };

    const toggleItem = (itemName: string) => {
        setOpenItems((prevOpenItems: OpenItems) => ({
            ...prevOpenItems,
            [itemName]: !prevOpenItems[itemName], // Toggle the item's state
        }));
    };

    const [navigationItems, setNavigationItems] = useState(
        clientNavigationPages(orderCount)
    );

    useEffect(() => {
        setNavigationItems(clientNavigationPages(orderCount));
    }, [orderCount]);

    useEffect(() => {
        const showIntro = searchParams.get("showIntro") === "true";
        const alreadyShown = Cookies.get("showIntro");
        if (!alreadyShown && showIntro) {
            const timeout = setTimeout(() => setRun(true), 500); // Delay of 1 second
            Cookies.set("showIntro", "false");
            return () => clearTimeout(timeout); // Cleanup timeout on unmount
        }
    }, [searchParams]);

    const [run, setRun] = useState(false);

    const steps = [
        {
            target: ".dashboard-button", // Selector for the first button
            content:
                "Welcome to your dashboard! Get a comprehensive view of your stats and easily navigate your operations from here.",
            disableBeacon: true,
        },
        {
            target: ".inbound-button", // Selector for the first button
            content:
                "Track shipments that are currently en route to the warehouse. Stay updated on their status in real-time.",
            disableBeacon: true,
        },
        {
            target: ".received-button", // Selector for the second button
            content:
                "View shipments that have arrived at the warehouse but are pending check-in.",
            disableBeacon: true,
        },
        {
            target: ".inventory-button", // Selector for the third button
            content:
                "View shipments that have been successfully checked in from the inbound section. Access detailed inventory records for better control.",
            disableBeacon: true,
        },
        {
            target: ".outbound-button", // Selector for the third button
            content:
                "Handle all outbound shipments here, from preparation to dispatch. Ensure orders are efficiently fulfilled and shipped.",
            disableBeacon: true,
        },
        {
            target: ".billing-button", // Selector for the third button
            content:
                "Review and manage billing for your shipments. Stay on top of charges and payments for seamless operations.",
            disableBeacon: true,
        },
        {
            target: ".referrals-button", // Selector for the third button
            content: "Track and manage referral activities here",
            disableBeacon: true,
        },
    ];

    return (
        <div className="hidden lg:block">
            <div className="flex flex-row">
                {/* Sidebar */}
                <div
                    className={`fixed scrollbar-hidden overflow-y-auto scroll- z-20 top-0 h-screen pt-6 flex flex-col justify-between bg-white border-r border-gray-300 transition-all duration-300 ease-in-out ${
                        isMinimized ? "w-16" : "w-64"
                    }`}
                >
                    <div className="z-20">
                        <div
                            className={`flex ${
                                isMinimized
                                    ? "justify-center"
                                    : "justify-start pl-4"
                            }`}
                        >
                            <img
                                alt="logo"
                                src="/branding/logo-white.jpeg"
                                className={`${
                                    isMinimized ? "w-10" : "w-20"
                                } h-auto transition-all duration-300 cursor-pointer`}
                                onClick={() => navigate("/")}
                            />
                        </div>
                        <div className="pt-10"></div>
                        <nav
                            className={`flex flex-col mx-4 z-20 ${
                                isMinimized ? "items-center" : ""
                            }`}
                        >
                            <ul
                                role="list"
                                className="flex flex-1 flex-col gap-y-7 z-20"
                            >
                                <li>
                                    <ul
                                        role="list"
                                        className="-mx-2 space-y-1 z-20"
                                    >
                                        {navigationItems.map((item) => (
                                            <li key={item.name}>
                                                {!item.children ? (
                                                    <a
                                                        onClick={() =>
                                                            navigate(item.href)
                                                        }
                                                        className={classNames(
                                                            currentPath ===
                                                                item.href
                                                                ? "bg-gray-100 text-accent"
                                                                : "hover:bg-gray-100 text-gray-600",
                                                            `group z-20 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 items-center ${item.customClassName}`
                                                        )}
                                                    >
                                                        <item.icon
                                                            aria-hidden="true"
                                                            className="h-6 w-6 shrink-0"
                                                        />
                                                        {!isMinimized && (
                                                            <span className="text-sm font-semibold">
                                                                {item.name}
                                                            </span>
                                                        )}
                                                    </a>
                                                ) : (
                                                    <Disclosure as="div">
                                                        {!isMinimized ? (
                                                            <DisclosureButton
                                                                onClick={() => {
                                                                    toggleItem(
                                                                        item.name
                                                                    );
                                                                    navigate(
                                                                        item.href
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    item.current
                                                                        ? "bg-gray-100 text-accent"
                                                                        : "hover:bg-gray-100 text-gray-600",
                                                                    `${item.customClassName} group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6`
                                                                )}
                                                            >
                                                                <item.icon
                                                                    aria-hidden="true"
                                                                    className="h-6 w-6 shrink-0"
                                                                />
                                                                {!isMinimized && (
                                                                    <>
                                                                        {
                                                                            item.name
                                                                        }
                                                                        <ChevronRightIcon
                                                                            aria-hidden="true"
                                                                            className="ml-auto h-5 w-5 shrink-0 text-gray-700 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                                                        />
                                                                    </>
                                                                )}
                                                            </DisclosureButton>
                                                        ) : (
                                                            <SidebarIconWithPopup
                                                                item={item}
                                                                currentPath={
                                                                    currentPath
                                                                }
                                                            />
                                                        )}
                                                        {openItems[item.name] &&
                                                            !isMinimized && (
                                                                <ul className="mt-1 px-2">
                                                                    {item.children.map(
                                                                        (
                                                                            subItem
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    subItem.name
                                                                                }
                                                                            >
                                                                                <DisclosureButton
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            subItem.href
                                                                                        )
                                                                                    }
                                                                                    className={`
                                                                                ${
                                                                                    currentPath ===
                                                                                    subItem.href
                                                                                        ? "bg-gray-100"
                                                                                        : "hover:bg-gray-50"
                                                                                } flex w-full rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 justify-bewtween"
                                                                            `}
                                                                                >
                                                                                    {
                                                                                        subItem.name
                                                                                    }
                                                                                    {subItem.count &&
                                                                                        !isMinimized && (
                                                                                            <span
                                                                                                aria-hidden="true"
                                                                                                className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                                                                                            >
                                                                                                {
                                                                                                    subItem.count
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                </DisclosureButton>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                    {/* </DisclosurePanel> */}
                                                                </ul>
                                                            )}
                                                    </Disclosure>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="flex flex-col justify-center">
                        <button
                            onClick={toggleSidebar}
                            className="p-2 mb-4 mx-auto bg-gray-100 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        >
                            {isMinimized ? (
                                <ChevronRightIcon className="h-6 w-6 text-gray-600" />
                            ) : (
                                <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
                            )}
                        </button>
                        <div
                            className={`pb-4 ${
                                isMinimized
                                    ? "justify-center flex"
                                    : "pl-4 pr-2"
                            } z-20`}
                        >
                            <Settings isMinimized={isMinimized} />
                        </div>
                    </div>
                </div>

                <div
                    className={`transition-all duration-300 ease-in-out ${
                        isMinimized ? "ml-16" : "ml-64"
                    }`}
                ></div>
            </div>

            {/* Joyride Tour Component */}
            <Joyride
                steps={steps}
                run={run}
                continuous
                showProgress
                showSkipButton
                styles={{
                    options: {
                        zIndex: 1000,
                        primaryColor: "#9e7ad8",
                    },
                }}
                debug
            />
        </div>
    );
}
