/** @format */

import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

// Register required Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChart: React.FC<{ labels: unknown[]; data_list: unknown[] }> = ({
    labels,
    data_list,
}) => {
    // Data and options for the chart
    const data = {
        labels: labels,
        datasets: [
            {
                data: data_list,
                backgroundColor: "rgba(158, 122, 216, 1)",
                borderColor: "rgba(158, 122, 216, 1)",
                borderWidth: 0,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            // Disable title
            title: {
                display: false,
            },
            // Disable legend
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                backgroundColor: "#ffffff", // White background
                titleColor: "#000000", // Black title text
                bodyColor: "#000000", // Black body text
                borderWidth: 1,
                borderColor: "rgba(158, 122, 216, 1)", // Add a border
                padding: 10,
                cornerRadius: 8, // Rounded corners
                displayColors: false, // Remove color indicators
                // callbacks: {
                //     label: function (context) {
                //         return `Count: ${context.raw}`; // Customize tooltip label
                //     },
                // },
            },
        },
        scales: {
            x: {
                grid: {
                    display: true, // Optional: Hide grid lines for x-axis
                },
            },
            y: {
                grid: {
                    display: false, // Optional: Show grid lines for y-axis
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
